import React, { useEffect, useState } from 'react';
import { useGesture , useDrag} from '@use-gesture/react';
import { a,useSpring, animated } from '@react-spring/web'
import  './style.css';
import AppConfig from '../Home/Config';

const hostloca=AppConfig.cdnUrl;

var dragposTemp=0
var dragmyTemp=0
  var dragDir='up'
 var dragstate=0 
 var candarg=false
 var blockdrag=false
 //var jsondata=[]
 var firstin=0

function CircleButton(props) {


     const [itemalpha,setitemalpha]=useState(0)
     const [scaleitem,setscaleitem]=useState(100)
     const [tumb,settumb]=useState(props.data.tumb)
     const [bgc,setbgc]=useState('transparent')

     useEffect(() => {

 
        if(props.targetitem==props.index ){
          
            setscaleitem(100)
            setbgc('white')

        }else{

            setscaleitem(80)
            setbgc('#e0ddd3')
        }

        if(props.index>=props.targetitem-1 && props.index<= (Number(props.targetitem)+1)){
           // console.log(props.targetitem-1+'/'+props.index + '/' + (Number(props.targetitem)+1))
            setitemalpha(1)
        }else{
            setitemalpha(0)
           
        }
        
       // setitemalpha()
       
       if(props.data!=null ){
     
       settumb(props.data.tumb)
       
       }

       
     //  console.log(props.data)
    
      },[props])
    

    return (
    
        <div  style={{
            width: '18vw',
            height: '18vw',
            borderRadius: '50%',
            opacity:1,
             marginTop:'2vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition:`all .5s`,
             
          }}>
      <div style={{
        width: scaleitem+'%',
        height: scaleitem+'%',
        borderRadius: '50%',
       opacity:itemalpha,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition:`all .3s`,
        borderColor:'black',
       
         
      }}>
         <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            opacity:1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color:'black',
            borderColor:'#666',
            border: '.3px solid',
             
          }}
        > 
        <div
          style={{
            width: '92%',
            height: '92%',
            borderRadius: '50%',
           opacity:1,
            backgroundColor:bgc,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color:'black',
            
             
          }}
        >
             
             <img src={hostloca+tumb} style={{width:'100%',height:'100%'}} />
        
            
        </div>
        </div>

        </div>
      </div>
    );
  }

  let renewdressdata=[]
  let indexdata=[]

function SlidingButtons(props) {
  const [offset, setOffset] = useState(0);
  const items = Array.from({ length: 10 }, (_, i) => i + 1); // 创建10个按钮
  
  const [dragconstant,setdragconstant]=useState(0);
  const [anitime,setanitime]=useState(0);
   //var dressitems = Array.from({ length: 12 }, (_, index) => index ); // 每个圆形 div 的角度
   const [dressitems,setdressitems]=useState([])
   const [initsidemenu,setinitsidemenu]=useState(false)


  const [itemstate,setitemstate]=useState(props.dressid);
  const itemwith=window.innerWidth*.2
  const fixedPoint = { x: window.innerWidth-itemwith*1.4, y: window.innerHeight/4}; // 固定的开始点坐标
  const [sidemenualpha,setsidemenualpha]=useState(0)

  const { y } = useSpring({
    to: { y: offset },
    config: { tension: 250, friction: 20 }
  });

  

  
  useEffect(() => {


    
 
    if(props.data!=null){
        if(firstin==0){
            firstin=1
       
 


    //根据tag重新组成新数组。

       for(let i=0;i<props.data.length;i++){
       
          if(props.data[i].tag[0]==props.tag){
            
            renewdressdata.push(props.data[i])
            indexdata.push(props.data[i].item)//因为item序列被打乱，根据参照数组indx来定位
          }

       }


      setdressitems(renewdressdata)

     // console.log(dressitems[5].tumb)
      setinitsidemenu(true)
      console.log(renewdressdata)
      console.log(indexdata)
    //let dragtop=-dressitems.length*itemwith/2+itemwith*3
    //dragstate=parseInt(dressitems.length/2)-1
    //console.log('item state: '+dragstate)

   
    let dragtop=-itemwith* indexdata.indexOf(props.dressid)+itemwith*2
   // let dragtop=-itemwith*props.dressid+itemwith*2
   //dragstate=Number(props.dressid)
    
    dragstate=Number(indexdata.indexOf(props.dressid))
   
    
    
    setitemstate(dragstate) //目前目标
    setdragconstant(dragtop)
    dragposTemp=dragtop
   // console.log('side  menu item id: '+itemstate+' dress id '+props.dressid+'tag : '+ props.data[props.dressid].tag)

    console.log('side  menu item id: '+itemstate+' dress id '+props.dressid+'tag : '+ renewdressdata[indexdata.indexOf(props.dressid)].tag)

    }

    if(props.show){
      setsidemenualpha(1)
     }

     
   }

  
  },[props])

  




  const bindsidemenu = useGesture({
    onDragStart: ({movement: [mx, my] ,down}) => {
     // setIsDragging(true);
        if(down){
     
            dragmyTemp=my
          //  console.log('en'+dragposTemp)
         }
       
        
       
    },
    onDrag: ({active, movement: [mx, my] ,down}) => {
   if(blockdrag)return
      

    if(down && candarg ){
 
     const dx = mx - fixedPoint.x;
     const dy = my - fixedPoint.y;
     // const distance = Math.sqrt(dx * dx + dy * dy); // 计算直线距离
      const radians = Math.atan2(dy, dx);
 

     
      var tempstate=dragstate-parseInt(my/itemwith) //根据移动距离换算滚动到那个item
      if(tempstate<0){
        tempstate=0
      }else if(tempstate>dressitems.length-1){
        tempstate=dressitems.length-1
      }

      //console.log(tempstate)
      setitemstate(tempstate)   
 
      setanitime(0)
      setdragconstant(dragposTemp+my)
     

      //console.log(Math.abs(my))

       
       
       //  const  degrees = startangel-(radians * 180) / Math.PI+ indegrees;
         
        //     if(Math.abs((startangel-rotateangel))>6){
 

          //  }
       
      
        
       }
    }, 
    onDragEnd: ({movement: [mx, my] ,down}) => {
     // setIsDragging(false);
      // 拖动结束时的操作
      if(blockdrag)return
     

      candarg=true
      movesidemenu(my)
      

        
       
        
          

     //  setrotateangel(startangel)
       
     //console.log('drag end'+dragDir)
      
    },
  });


  function movesidemenu(my){

    var dargcount=Math.abs(parseInt(my/itemwith))
    
    

    let dircul=my-dragmyTemp
     //console.log(dircul)
     if(Math.abs(dircul)>itemwith/2 && Math.abs(dircul)<itemwith)dargcount=1
    setanitime(.3)
    if(dircul>0){
        
       
       
        dragDir='down'
        
          if(dragstate>0 && dragstate-dargcount>0){
            dragstate-=dargcount
           
            setdragconstant( dragposTemp+itemwith*dargcount)
            dragposTemp=dragposTemp+itemwith*dargcount
          }else{
            dragstate=0
            dragposTemp=itemwith*2
            setdragconstant(dragposTemp)

          }
    
    }else{


           
            if(dragstate<dressitems.length-1 && dragstate+dargcount<dressitems.length-1){
                dragstate+=dargcount
                setdragconstant( dragposTemp-itemwith*dargcount)
                dragposTemp= dragposTemp-itemwith*dargcount
            }else{
                dragstate=dressitems.length-1
                dragposTemp=-itemwith*dragstate+itemwith*2
                setdragconstant(dragposTemp) 

            }
             dragDir='up'
    }


        setitemstate(dragstate)   //序列号
        console.log('state '+indexdata[dragstate]) //转换成真实item的 id

        props.callback(indexdata[dragstate])

  }


  
  const transformStyle = {
   // transform: `translate(-50%, ${dragconstant}px) rotate(0rad)`,
    width:'30vw',
    opacity:1,
    height:'auto',
    borderRadius:0,
    left:0,
    top:dragconstant,
    transition:`all ${anitime}s`,
   
 };



  return (
    <div style={{position:'absolute',width:'30vw',height:'100vw',top:(window.innerHeight-window.innerWidth)/2-window.innerWidth*.1,left:window.innerWidth-window.innerWidth*.3,opacity:sidemenualpha}}>

        
 
       <a.div  tabIndex={-1}   {...bindsidemenu()}   className={'scollmeinu'}   style={transformStyle} >
          
       {initsidemenu? (dressitems.map((dressitem,index) => (
          <CircleButton  key={dressitem.item}  targetitem={itemstate} index={index} data={dressitem} />
           
 
       )) ):('') } 

          </a.div>
       
    </div>
  );
}

export default SlidingButtons;