import React, { useRef, useEffect ,useState } from 'react';
import Webcam from 'react-webcam';
import * as tf from '@tensorflow/tfjs';
//import * as tf from '@tensorflow/tfjs-core';
import * as poseDetection from '@tensorflow-models/pose-detection';
import '@tensorflow/tfjs-backend-webgl';


import { drawKeypoints,drawSkeleton } from '../Home/utilities'
import AppConfig from '../Home/Config';


const hostloca=AppConfig.cdnUrl;

var camc=0

var tfjsdetecter=null

var detectcount=0
var detectorstate=false

var rundeticetime=20


// 计算iOS Safari不含地址栏的视窗高度
function getViewportHeight() {
  const element = document.createElement('div');
  element.style.position = 'fixed';
  element.style.height = '100vh';
  element.style.width = '1px';
  document.body.appendChild(element);

  const viewportHeight = element.offsetHeight;
  document.body.removeChild(element);

  return viewportHeight;
}




const BlazePosePhoto = (props) => {
  const webcamRef = useRef(null);
  const imageRef = useRef(null);
  const imageRefbg = useRef(null);
  const canvasRef = useRef(null);
  
  const [imageRefbgimg, setimageRefbgimg] = useState(hostloca+'Assets/baloupose.jpg');
  const [trackdata, setTrack] = useState(640);
  const [camLeft, setCamLeft] = useState(-window.innerWidth);
  const [tracklog, settracklog] = useState('Uploading');
  const [overlayalpha,setoverlayalpha]=useState(0);
  const [showbgimg,setshowbgimg]=useState('none');

  
  
  var vh=getViewportHeight()
  const [overlay, setoverlay] = useState([window.innerWidth,vh]);


  const modelUrl =  hostloca+'GraphModel/model.json'; // 替换为你的模型 URL
  const lanmarkUrl =  hostloca+'LandMark/model.json'; // 替换为你的模型 URL
  //const modelUrl ="https://tfhub.dev/mediapipe/tfjs-model/blazepose_3d/detector/1/model.json?tfjs-format=file"
  
  

  const [Posemodel, setModel] = useState(null);

  
  const intervalRef = useRef(null);

  

   
  

  /*
  useEffect(() => {
    const loadModel = async () => {
      try {
        const loadedModel = await tf.loadGraphModel(modelUrl);
        setModel(loadedModel);
        console.log('Model loaded successfully');
      } catch (error) {
        console.error('Failed to load model:', error);
      }
    };

    loadModel();
  }, [modelUrl]);

   */

  const runBlazePose = async () => {
      

    await tf.setBackend('webgl');


    async function loadModel() {
      // 确保 TensorFlow.js 使用 WebGL 加速
        
      await tf.setBackend('webgl');
    
     
      //await tf.loadGraphModel(modelUrl);

      //await tf.loadModel(modelUrl);
 
     // 加载  模型
     const model = poseDetection.SupportedModels.BlazePose;

     const detectorConfig = {
      runtime: 'tfjs',
      enableSmoothing: true,
      modelType: 'lite',
      detectorModelUrl:modelUrl,
      landmarkModelUrl:lanmarkUrl
      
       
    };

   
    setoverlayalpha(1)
    console.log('load Detector model')
     const detector = await poseDetection.createDetector(model, detectorConfig);
     settracklog('load Detector')//load Detector
    
     tfjsdetecter=detector
 
   return detector;
 
 }



 
   loadModel().then(detector => {
     console.log('模型加载成功', detector);
     
      
     settracklog(' ') //Tensorflow Loaded
    
     //intervalRef.current = setInterval(() => {
         // detect(tfjsdetecter);
          loadAndPredictImage(tfjsdetecter)
          
       // }, 100);
 /*
         // 清理定时器
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
       */
        
   });
 

  
   
  };


  const loadAndPredictImage = async (detector) => {
       

        
  
    // 确保图片加载完成
    imageRef.current.onload = async () => {

        console.log(imageRef.current.width)
        

        if(camc==0){
            camc=1
            const viewportHeight = getViewportHeight(); //获取准确的高度，排除safari地址栏
           
            console.log('Start Pose Detecting')
            settracklog('Pose detecting')
            canvasRef.current.width = imageRef.current.width;
            canvasRef.current.height = imageRef.current.height;   //原始尺寸
      
             setoverlay([window.innerWidth,viewportHeight])
               
            const camscreenW=viewportHeight*imageRef.current.width/imageRef.current.height
             
            //console.log(window.innerWidth + 'video width: ' + camscreenW)
      
            setCamLeft((window.innerWidth-imageRef.current.width)/2) 

          
            setTrack(viewportHeight)

            setshowbgimg('block')
          }
          
          intervalRef.current = setInterval(() => {
          
            if(detectcount<=rundeticetime){
               detect(tfjsdetecter);
               console.log('detecting')
            }else{
               // if(detectorstate==false){
                   
                clearInterval(intervalRef.current);
                    
              //  }
                
               
            }
           
          
          }, 100);

         


     // const poses = await detector.estimatePoses(imageRef.current);
     // drawResults(poses);
    };

    if(props.photourl==null || props.photourl=='' || props.photourl==undefined){
        imageRef.current.src = hostloca+'Assets/baloupose.jpg'; // 替换为你的图片路径
        console.log('photo target undefined use def photo')
    }else{
        imageRef.current.src =hostloca+props.photourl
        
        setimageRefbgimg(hostloca+props.photourl)
    }

    
  };



  const detect = async (detector) => {

    detectcount+=1
    if(detectcount>rundeticetime){
        console.log(detectcount)
        settracklog('Detect Pose')
        setoverlayalpha(0)
        props.callback(detectorstate)
        clearInterval(intervalRef.current);
       
        return
       
    }

    
     const video = imageRef.current;
      const videoWidth = imageRef.current.width
      const videoHeight = imageRef.current.height; 

     

     // webcamRef.current.video.width = videoWidth;
     // webcamRef.current.video.height = videoHeight;

     // const poses = await detector.estimatePoses(video);
      const poses = await detector.estimatePoses(imageRef.current);

       
   

    

      if(poses !==null && poses.length !==0 && poses[0].keypoints!== "undefined"){

        
     

      var keypoints=poses[0].keypoints

      const keypoints3D=poses[0].keypoints3D

      let centpX=keypoints[12].x+(keypoints[11].x-keypoints[12].x)/2
      let centpY=keypoints[12].y+(keypoints[11].y-keypoints[12].y)/2
      let centpZ=keypoints[12].z+(keypoints[11].z-keypoints[12].z)/2

      const centerpoint={x:centpX,y:centpY,z:centpZ,score:.66,name:'center'}
       //add a center point
      keypoints.push(centerpoint)


      let hitcentpX=keypoints[24].x+(keypoints[23].x-keypoints[24].x)/2
      let hitcentpY=keypoints[24].y+(keypoints[23].y-keypoints[24].y)/2
      let hitcentpZ=keypoints[24].z+(keypoints[23].z-keypoints[24].z)/2

      const hitcenterpoint={x:hitcentpX,y:hitcentpY,z:hitcentpZ,score:.66,name:'hitcenter'}
       //add a center point
      keypoints.push(hitcenterpoint)



     //console.log(keypoints)
       

        const ctx = canvasRef.current.getContext('2d');
          ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
         ctx.drawImage(video, 0, 0, videoWidth, videoHeight);
        
         // console.log(keypoints)


        //ket point
        
        
       if(props.testmode)  drawKeypoints(keypoints,.5,ctx)



          //console.log(keypoints[11].name)
          var Pdata=[keypoints3D[0],keypoints3D[11],keypoints3D[12],keypoints3D[13],keypoints3D[14],keypoints3D[15],keypoints3D[16],keypoints3D[23],keypoints3D[24],keypoints3D[25],keypoints3D[26],keypoints3D[27],keypoints3D[28]]
          Pdata.push(centerpoint)  //array 13
          Pdata.push(keypoints[0])
          Pdata.push(keypoints[9])
          Pdata.push(keypoints[10])
          Pdata.push({w:videoWidth,h:videoHeight})
          Pdata.push(keypoints[23])
          Pdata.push(keypoints[15])//rh
          Pdata.push(keypoints[16])//lh
          Pdata.push(keypoints[13])//ra
          Pdata.push(keypoints[14])//la
          Pdata.push(keypoints[25])//ltr  array23
          Pdata.push(keypoints[26])//ltl
          Pdata.push(keypoints[27])//lfr
          Pdata.push(keypoints[28])//lfl
          Pdata.push(hitcenterpoint)//lfl  array27
          Pdata.push(keypoints[11])//rsoder  array28
          Pdata.push(keypoints[12])//lsoder array29


          
 
          props.poseData(Pdata)
      }

   

       
    

    
  };


   // 绘制Webcam画面到Canvas
   const drawWebcamImageToCanvas = () => {
    const video = webcamRef.current.video;
    const canvas = canvasRef.current;
    if (canvas && video) {
      const context = canvas.getContext('2d');
      // 调整canvas尺寸以匹配Webcam视频流尺寸
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

       
   
      // 你可以在这里添加更多的图像处理逻辑

      // 持续绘制
      requestAnimationFrame(drawWebcamImageToCanvas);
    }
  };

  useEffect(() => {
    
   // drawWebcamImageToCanvas();
  }, [webcamRef, canvasRef]);




  useEffect(() => {
 


    runBlazePose();
   
  }, []);


 
 


 
  



  const btnclick=()=>{
    
    console.log('click')

  }

  

  return (
    <div>
        <img  src={imageRefbgimg} alt="Pose" style={{position:"fixed",top:-window.innerHeight*.05,left:-window.innerHeight*.05,right:0,textAlign:"center",zIndex:9,height:window.innerHeight*1.1,filter:'blur(16px)', display: showbgimg}} />
        
      <img ref={imageRef} alt="Pose" style={{position:"fixed",top:0,left:camLeft,right:0,textAlign:"center",zIndex:9,height:window.innerHeight*.80, display: 'block',opacity:0}} />
      
      <canvas id={'posecanvas'} ref={canvasRef}  style={{position:"fixed",top:0,left:camLeft,right:0,textAlign:"center",zIndex:9,height:window.innerHeight*.80, display: 'block' }}/>
     
      
      <div    style={{position:'absolute',width:overlay[0],height:overlay[1],
               top:0,left:0,backgroundColor:'#222222',textAlign:'center',
               zIndex:20,opacity:overlayalpha,alignItems:'center',color:'#fff',display:'flex',justifyContent:'center'
    }}> <img src={hostloca+'Assets/loading2.gif'} style={{width:'20vw',marginTop:'-16vh'}}/> </div>
    
    
 
      {/*

      <Webcam ref={webcamRef}  mirrored={false} style={{position:"fixed",top:0,left:0,right:0,textAlign:"center",zIndex:9,height:'100vh', display: 'block',opacity:0}} />
      
      <div  onClick={()=>btnclick()}  style={{position:'absolute',width:100,height:100,backgroundColor:'yellow',right:20,textAlign:'center',zIndex:20,}}>{trackdata}</div>
    
 */}
     
     
      </div>
  );
};

export default BlazePosePhoto;