import React, { useRef,useEffect,useState } from 'react'
import { Canvas, useFrame, useThree,useLoader,extend } from '@react-three/fiber'
import * as THREE from 'three'
import { Plane } from 'three';
import { Stats,useIntersect,Line, Image, Sky, ScrollControls, Scroll ,Loader,useScroll,
    useGLTF,OrbitControls ,PerspectiveCamera,useProgress,Html,Decal,Lightformer,
    Caustics,MeshTransmissionMaterial,Environment,RandomizedLight,AccumulativeShadows,RenderTexture,Text,Edges,CameraControls} from '@react-three/drei'

import { GLTFLoader } from 'three/addons/loaders/GLTFLoader';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';
import { BrowserView, MobileView, isBrowser, isMobile ,isAndroid} from 'react-device-detect';

import {gsap,TweenMax} from "gsap";

import AppConfig from './Config.js';
//import BlazePoseWebcam from '../components/BlazePoseWebcam'
import BlazePosePhoto from '../components/BlazePosePhoto'
import SlidingButtons from '../components/SlidingButtons.js';
import Dressitems from './Dressitems.js';
import Dressinfo from '../components/Dressinfo.js';

import Coordinate from './CoordinateReference'
import { useNavigate } from 'react-router-dom';
import { CCDIKSolver, CCDIKHelper } from 'three/examples/jsm/animation/CCDIKSolver.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';


const hostloca=AppConfig.cdnUrl;
var first_in=true
var Android=false

 //pose data

 var setPosedata=[]
 var setPosedataToWorld=[]

 let alignposeTolandmark=false

 var matchPose=[]
 var matchPose2d=[]
 var detector=false
 var timecount=0
 var deressid=0
 var sceneload=false
 var arm=[]
 var modelArmature=[]

 var capturedata=null
 let dressalopa=0


 var testmode=false

 var pointer='visible'
 let solverint=false
 let updateik=2
 let Tfcontroller=false
 let tre=null
 let setclothsize=1
 let sizeid=2
 let pid=0



    let ph=window.innerHeight*.80
    let headareasize=ph*.06
    let handareasize=ph*.11
    let foodareasize=ph*.2

    let headareatop=ph*.15
    let handtop=ph*.48
    let footareatop=ph-foodareasize/1.3


    let matcharea_state=true



 if(testmode)pointer='none'

/**
 * 
 * pose date to tree
 */

  // 创建一个数组来存储每条线的引用
  const numberOfLines = 12;
      // 生成初始线条的函数
    const generateLines = () => Array.from({ length: numberOfLines }, () => ({
      // 每条线由两个随机点组成
      points: [
        [Math.random() * 10 - 5, Math.random() * 10 - 5, Math.random() * 10 - 5],
        [Math.random() * 10 - 5, Math.random() * 10 - 5, Math.random() * 10 - 5]
      ] 
    }));


  // 生成随机颜色的函数
  const generateRandomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);



function Posescene(props){
  const { size, viewport,gl, camera } = useThree()

  const [showgl, setshowgl] = useState(false);
  let worldw = size.width
  let worldh = size.height

 
  const bodysketch= useRef()

  const boneref=useRef()

  const rotabox=useRef()
  const dataRef = useRef('Some Data');

  const scaleV=-30

  const ball = useRef()
   // 创建一个虚拟的Object3D对象
   const Vobject = new THREE.Object3D();
   const VarmtLobj = new THREE.Object3D();
   const VarmbLobj = new THREE.Object3D();
   const VarmtRobj = new THREE.Object3D();
   const VarmbRobj = new THREE.Object3D();

   const ttobj = new THREE.Object3D();

  useEffect(() => {
    const handleResize = () => {
      // 更新状态或重新计算布局
       
     // worldh=780

      //alert(worldh)
    };
  
    window.addEventListener('resize', handleResize);
    
    // 初始检查
    handleResize();
  
    return () => window.removeEventListener('resize', handleResize);
  }, []); // 空依赖数组表示此效果只在组件挂载和卸载时运行
  


    const [Poselines, setLines] = useState(generateLines);

   

    // 更新线条位置的函数
   // const updateLines = () => setLines(generateLines());

     // 使用useState初始化一个数组，用于存储对每个盒子的引用
     const lineRefs = useRef(Poselines.map(() => React.createRef()));



    
    // 创建包含10个随机颜色的数组
    const randomColors = Array.from({ length: 13 }, generateRandomColor);

    // 使用useState初始化一个数组，用于存储对每个盒子的引用
    const boxRefs = useRef(randomColors.map(() => React.createRef()));


 
    /**
     * 
     * 
     */



    function raycastpose(moveshifx,moveshify){


     // 将屏幕坐标转换为标准化设备坐标 (NDC)
     const NDx = (moveshifx / worldw) * 2 - 1;
     const NDy = -(moveshify / worldh) * 2 + 1;
 
       // 创建一个新的 THREE.Vector2 实例用于 raycaster
     const posetwo = new THREE.Vector2(NDx, NDy);
 
 
     // 创建一个新的raycaster
     const raycaster = new THREE.Raycaster();
    
 
      // 使用raycaster来获取点击位置的3D坐标
      raycaster.setFromCamera(posetwo, camera);
       // 定义z=0的平面
       const planeZ = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
       const newtarget = new THREE.Vector3();
       raycaster.ray.intersectPlane(planeZ, newtarget);
       return newtarget
    }




     // 根据索引更新盒子的位置
    const updateBoxPosition=(index,setPosedata,matchprops)=>{

      if(alignposeTolandmark)return
    const ref = boxRefs.current[index];
   
    
    if (ref.current && setPosedata.length>0) {
        //将中心位置移至脖颈处
      var stY=-(setPosedata[1].y-setPosedata[7].y)*scaleV
      // 设置新的位置
      ref.current.position.set(
        -setPosedata[index].x*scaleV,
        setPosedata[index].y*scaleV+stY,
        setPosedata[index].z*scaleV,
      );

     
    // console.log(setPosedata[13].x)
    var scalesp=0.04
     

    
    //console.log(worldPosition)
    
     
     var videocanvasW=worldh*setPosedata[17].w/setPosedata[17].h //实际canvas放大尺寸

     var videocanvasH=videocanvasW*setPosedata[17].h/setPosedata[17].w //实际canvas放大尺寸

     var scaleradio= videocanvasW/setPosedata[17].w //实际位移放大倍率
     var scaleradioy= setPosedata[17].h/worldh//实际位移放大倍率

     var scaleradiox= setPosedata[17].w/worldw//实际位移放大倍率


     var moveshifx=setPosedata[13].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
 
     //console.log(scaleradioy)
     
     var moveshify=(setPosedata[13].y )  // why 4?????-(worldh-setPosedata[17].h)/2  
     //console.log(setPosedata[13].y)
    
     //console.log(moveshify)
     


     var bonesqu=(setPosedata[15].y*scaleradioy - setPosedata[14].y*scaleradioy)
     var bonesqu2=(setPosedata[18].y*scaleradioy - setPosedata[13].y*scaleradioy)
     var bonesizesqu=bonesqu/bonesqu2  //头身比

     //if(updateik<2)console.log('nose moth/negtohit :' +bonesizesqu )

    var bodysizespf=worldw/worldh
    matchPose[4]=1.04
    var scalesq=1.7
    if(bodysizespf>0.52){
        scalesq=2.4
        matchPose[4]=1.06
    }
   //  var ballposesize=(setPosedata[15].y*scaleradioy - setPosedata[14].y*scaleradioy)/worldh*64 //放大比例鼻嘴距离  误差大
   // var ballposesize=(setPosedata[15].y*scaleradioy - setPosedata[14].y*scaleradioy)/worldw*28//放大比例鼻嘴距离

   var ballposesize3=(setPosedata[18].y*scaleradioy - setPosedata[13].y*scaleradioy)/window.innerHeight*3.73*setclothsize //颈腰距 3.93离


   var ballposesize=(setPosedata[28].x*scaleradioy - setPosedata[29].x*scaleradioy)/window.innerHeight*5.8 //肩宽 正面较准确




    if(updateik<2)console.log('bodysize :' +bodysizespf ,ballposesize)



     

     var ballposez=ballposesize

     const target=raycastpose(moveshifx,moveshify)
     


      if (target) {
       
 
     //位移匹配

      ball.current.position.set(target.x,target.y,0)
      ball.current.scale.set(ballposez,ballposez,ballposez)


     

       //中心球
      rotabox.current.position.set(target.x,target.y,0) 

       bodysketch.current.position.set(target.x,target.y,0)
       bodysketch.current.scale.set(ballposez,ballposez,ballposez)

      //update match pose 缩放和位移值得

      matchPose[0]=target
      matchPose[1]=ballposez
      
      matchPose[3]=bonesizesqu

      //肢体2d相应位置

      var moveshifrax=setPosedata[21].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
      var moveshifray=(setPosedata[21].y )  // 
      const targetra=raycastpose(moveshifrax,moveshifray)
      matchPose[5]=targetra
      var moveshiflax=setPosedata[22].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
      var moveshiflay=(setPosedata[22].y )  // 
      const targetla=raycastpose(moveshiflax,moveshiflay)
      matchPose[6]=targetla


      matchPose2d[0]=[moveshifrax,moveshifray]
      matchPose2d[1]=[moveshiflax,moveshiflay]

      //hand l & r

      var moveshifrhx=setPosedata[19].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
      var moveshifrhy=(setPosedata[19].y )  // 
      const targetrh=raycastpose(moveshifrhx,moveshifrhy)
      matchPose[7]=targetrh
      var moveshiflhx=setPosedata[20].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
      var moveshiflhy=(setPosedata[20].y )  // 
      const targetlh=raycastpose(moveshiflhx,moveshiflhy)
      matchPose[8]=targetlh

      matchPose2d[2]=[moveshifrhx,moveshifrhy]
      matchPose2d[3]=[moveshiflhx,moveshiflhy]
      
      //leg l & r

      var moveshifrhx=setPosedata[23].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
      var moveshifrhy=(setPosedata[23].y )  // 
      const targetltr=raycastpose(moveshifrhx,moveshifrhy)
      matchPose[9]=targetltr
      var moveshiflhx=setPosedata[24].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
      var moveshiflhy=(setPosedata[24].y )  // 
      const targetltl=raycastpose(moveshiflhx,moveshiflhy)
      matchPose[10]=targetltl


      matchPose2d[4]=[moveshifrhx,moveshifrhy]
      matchPose2d[5]=[moveshiflhx,moveshiflhy]

      //foot l & r

      var moveshifrhx=setPosedata[25].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
      var moveshifrhy=(setPosedata[25].y )  // 
      const targetlfr=raycastpose(moveshifrhx,moveshifrhy)
      matchPose[11]=targetlfr
      var moveshiflhx=setPosedata[26].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
      var moveshiflhy=(setPosedata[26].y )  // 
      const targetlfl=raycastpose(moveshiflhx,moveshiflhy)
      matchPose[12]=targetlfl


      matchPose2d[6]=[moveshifrhx,moveshifrhy]

      matchPose2d[7]=[moveshiflhx,moveshiflhy]

       //hit center

      var moveshifcenterhitx=setPosedata[27].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
      var moveshifcenterhity=(setPosedata[27].y )  // 
      const targetcenterhit=raycastpose(moveshifcenterhitx,moveshifcenterhity)
      matchPose[13]=targetcenterhit
      




        //head center

        var moveshifhcenterhitx=setPosedata[14].x+(worldw-setPosedata[17].w)/2 //中心点实际移动位置
        var moveshifhcenterhity=(setPosedata[14].y )  // 
       
      matchPose2d[8]=[moveshifhcenterhitx,moveshifhcenterhity]



     //console.log('----------------------------------------------'+matchPose[12].x)
     
     matchprops()

      const worldPosition = new THREE.Vector3();
      ref.current.getWorldPosition(worldPosition)

       const scaleP=1
       //输出坐标，整体缩放，整体位移
      const postr=new THREE.Vector3(-setPosedata[index].x*scaleV*scaleP,(setPosedata[index].y*scaleV+stY)*scaleP,(setPosedata[index].z*scaleV)*scaleP);

      setPosedataToWorld[index]=postr

      }

    }
  };





     const updatelinePosition=(index,setPosedata)=>{
      if(alignposeTolandmark)return
    


    if ( setPosedata.length>0) {
      var stY=-(setPosedata[1].y-setPosedata[7].y)*scaleV //偏移量
      const updategenLines = [
      { points: [-setPosedata[1].x*scaleV, setPosedata[1].y*scaleV+stY, setPosedata[1].z*scaleV,-setPosedata[2].x*scaleV,setPosedata[2].y*scaleV+stY, setPosedata[2].z*scaleV]},
      { points: [-setPosedata[1].x*scaleV, setPosedata[1].y*scaleV+stY, setPosedata[1].z*scaleV,-setPosedata[3].x*scaleV,setPosedata[3].y*scaleV+stY, setPosedata[3].z*scaleV]},
      { points: [-setPosedata[2].x*scaleV, setPosedata[2].y*scaleV+stY, setPosedata[2].z*scaleV,-setPosedata[4].x*scaleV,setPosedata[4].y*scaleV+stY, setPosedata[4].z*scaleV]},
      { points: [-setPosedata[3].x*scaleV, setPosedata[3].y*scaleV+stY, setPosedata[3].z*scaleV,-setPosedata[5].x*scaleV,setPosedata[5].y*scaleV+stY, setPosedata[5].z*scaleV]},
      { points: [-setPosedata[4].x*scaleV, setPosedata[4].y*scaleV+stY, setPosedata[4].z*scaleV,-setPosedata[6].x*scaleV,setPosedata[6].y*scaleV+stY, setPosedata[6].z*scaleV]},
      { points: [-setPosedata[1].x*scaleV, setPosedata[1].y*scaleV+stY, setPosedata[1].z*scaleV,-setPosedata[7].x*scaleV,setPosedata[7].y*scaleV+stY, setPosedata[7].z*scaleV]},
      { points: [-setPosedata[2].x*scaleV, setPosedata[2].y*scaleV+stY, setPosedata[2].z*scaleV,-setPosedata[8].x*scaleV,setPosedata[8].y*scaleV+stY, setPosedata[8].z*scaleV]},
      { points: [-setPosedata[7].x*scaleV, setPosedata[7].y*scaleV+stY, setPosedata[7].z*scaleV,-setPosedata[8].x*scaleV,setPosedata[8].y*scaleV+stY, setPosedata[8].z*scaleV]},
      { points: [-setPosedata[7].x*scaleV, setPosedata[7].y*scaleV+stY, setPosedata[7].z*scaleV,-setPosedata[9].x*scaleV,setPosedata[9].y*scaleV+stY, setPosedata[9].z*scaleV]},
      { points: [-setPosedata[8].x*scaleV, setPosedata[8].y*scaleV+stY, setPosedata[8].z*scaleV,-setPosedata[10].x*scaleV,setPosedata[10].y*scaleV+stY, setPosedata[10].z*scaleV]},
      { points: [-setPosedata[9].x*scaleV, setPosedata[9].y*scaleV+stY, setPosedata[9].z*scaleV,-setPosedata[11].x*scaleV,setPosedata[11].y*scaleV+stY, setPosedata[11].z*scaleV]},
      { points: [-setPosedata[10].x*scaleV, setPosedata[10].y*scaleV+stY, setPosedata[10].z*scaleV,-setPosedata[12].x*scaleV,setPosedata[12].y*scaleV+stY, setPosedata[12].z*scaleV]}
    ]
      
    const lineref = lineRefs.current[index];
 
     

   lineref.current.geometry.setPositions(updategenLines[index].points);
  
     //setLines(updategenLines) 
     // console.log(Poselines)

     //以肩膀为参照 调整旋转角度

     if(index==0){     

      const pa=new THREE.Vector3(updategenLines[index].points[0],updategenLines[index].points[1],updategenLines[index].points[2])
      const pb=new THREE.Vector3(updategenLines[index].points[3],updategenLines[index].points[4],updategenLines[index].points[5])
       
       // 使用lookAt方法使object "看向" 由A到B的方向
    Vobject.lookAt(pa.sub(pb));

    // 从object中提取旋转值
    const rotationV = Vobject.rotation;

    rotabox.current.rotation.x=rotationV.x
    rotabox.current.rotation.y=rotationV.y+Math.PI
    rotabox.current.rotation.z=rotationV.z

    matchPose[2]=rotabox.current.rotation

  
     }

      
    // 计算方向向量
     
   // matchPose[7]=ttobjrotate
   // matchPose[8]=directiony
   // console.log(direction)

  }
  }
 

  useFrame((state) => {
     
    if(detector==true == showgl==false){
        setshowgl(true)
     }

     if(!alignposeTolandmark){
     boxRefs.current.forEach((ref, index) => updateBoxPosition(index,setPosedata,props.match_callback));

     lineRefs.current.forEach((ref, index) => updatelinePosition(index,setPosedata));
     alignposeTolandmark=true
     }



      

  })


  return (

     <group visible={testmode}>

      <group ref={bodysketch} >
   
         {randomColors.map((color, index) => (
        <mesh
          key={index}
          ref={boxRefs.current[index]}
          position={[Math.random() * 5 - 2.5, Math.random() * 5 - 2.5, Math.random() * 5 - 2.5]}
          rotation={[Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI]}
        >
          <boxGeometry args={[.5, .5, .5]} />
          <meshStandardMaterial color={color} />
        </mesh>
        
      ))}


        {Poselines.map((line, index) => (
          <Line
            key={index}
            ref={lineRefs.current[index]}
            points={[[0, 0, 0], [0, 10, 0]]}
            color={'white'}
            lineWidth={1}
          />
        ))}



       <Line
            
            ref={boneref}
            points={[[0, 10, 0], [30, 10, 0]]}
            color={'yellow'}
            lineWidth={3}
          />


      </group>


       
      <mesh ref={rotabox}  >
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color={'hotpink'} opacity={0} wireframe={true}/>
        </mesh>
 


       


      <mesh ref={ball}>
        <sphereGeometry args={[1, 8, 8]} /> {/* args 参数分别为：半径，宽度分段数，高度分段数 */}
        <meshStandardMaterial color="royalblue"   /> {/* 设置球体的颜色 */}
      </mesh>

      </group>

  )

 
}




/**
 * 
 * 
 */


 
 
function boneLookAt_zfront(bone, targetWorldPosition) {
   // 确保骨骼的世界矩阵是最新的
   bone.updateMatrixWorld(true);

   // 获取骨骼的世界位置
   let boneWorldPosition = new THREE.Vector3();
   bone.getWorldPosition(boneWorldPosition);

   // 计算从骨骼到目标点的方向向量
   let targetDirection = targetWorldPosition.clone().sub(boneWorldPosition).normalize();

   // 创建一个表示骨骼“前向”的向量，这应该与你模型的前向对齐
   let boneFront = new THREE.Vector3(1, 0, 0); // 假设骨骼的前向是沿Z轴

   // 如果骨骼有父对象，考虑父对象的逆旋转
   if (bone.parent) {
       const parentInverseQuat = new THREE.Quaternion();
       bone.parent.getWorldQuaternion(parentInverseQuat).invert();
       targetDirection.applyQuaternion(parentInverseQuat);
       boneFront.applyQuaternion(parentInverseQuat);
       //console.log(parentInverseQuat)
   }

   // 计算需要的旋转四元数
   const quaternion = new THREE.Quaternion().setFromUnitVectors(boneFront, targetDirection);

   // 直接设置骨骼的四元数可能与骨骼动画系统冲突，视情况而定
   bone.quaternion.copy(quaternion);
}

 


function boneLookAt_zback(bone, targetWorldPosition,rigtype) {
    // 确保骨骼的世界矩阵是最新的
    bone.updateMatrixWorld(true);
 
    // 获取骨骼的世界位置
    let boneWorldPosition = new THREE.Vector3();
    bone.getWorldPosition(boneWorldPosition);
 
    // 计算从骨骼到目标点的方向向量
    let targetDirection = targetWorldPosition.clone().sub(boneWorldPosition).normalize();
 
    // 创建一个表示骨骼“前向”的向量，这应该与你模型的前向对齐
    let boneFront = new THREE.Vector3(-1, 0, 0); // 假设骨骼的前向是沿Z轴

    if(rigtype==2){
        boneFront= new THREE.Vector3(0, -1, 0);
    }else if(rigtype==3){
        boneFront= new THREE.Vector3(0, -1, 0);
    }
 
    // 如果骨骼有父对象，考虑父对象的逆旋转
    if (bone.parent) {
        const parentInverseQuat = new THREE.Quaternion();
        bone.parent.getWorldQuaternion(parentInverseQuat).invert();
        targetDirection.applyQuaternion(parentInverseQuat);
        boneFront.applyQuaternion(parentInverseQuat);
        //console.log(parentInverseQuat)
    }
 
    // 计算需要的旋转四元数
    const quaternion = new THREE.Quaternion().setFromUnitVectors(boneFront, targetDirection);
 
    // 直接设置骨骼的四元数可能与骨骼动画系统冲突，视情况而定
    bone.quaternion.copy(quaternion);
 }


/**
 * 移动并旋转剪裁平面
 * @param {THREE.Plane} clippingPlane - 剪裁平面
 * @param {number} x - 沿X轴的位移
 * @param {number} y - 沿Y轴的位移
 * @param {number} z - 沿Z轴的位移
 * @param {Object} rotation - 包含绕X、Y、Z轴旋转角度（弧度）的对象
 */
function moveAndRotateClippingPlane(clippingPlane, x, y, z, rotation) {
    // 创建位移向量
    const translation = new THREE.Vector3(x, y, z);
    
    // 创建旋转四元数
    const quaternion = new THREE.Quaternion();
    quaternion.setFromEuler(new THREE.Euler(rotation.x, rotation.y, rotation.z, 'XYZ'));
    
    // 应用旋转到平面法线
    const rotatedNormal = clippingPlane.normal.clone().applyQuaternion(quaternion);
    
    // 计算旋转后法线与位移向量的点积，更新平面常数
    const constantAfterTranslation = -rotatedNormal.dot(translation);
    
    // 更新剪裁平面的法线和常数
   // clippingPlane.normal.copy(rotatedNormal);
    clippingPlane.constant = constantAfterTranslation;
}


function ClippingPlaneHelper(clippingPlane, size = 10, color = 0xff0000 ) {
    const { scene } = useThree();
  
    useEffect(() => {
     // const planeHelper = new THREE.PlaneHelper(clippingPlane, size, color);
     // scene.add(planeHelper);
  

      const helpers = new THREE.Group();
      helpers.add( new THREE.PlaneHelper( clippingPlane[ 0 ], size, 0xff0000 ) );
      helpers.add( new THREE.PlaneHelper( clippingPlane[ 1 ], size, 0x00ff00 ) );
      helpers.add( new THREE.PlaneHelper( clippingPlane[ 2 ], size, 0x0000ff ) );
      helpers.visible = true;
      scene.add( helpers );

      // 清理：从场景中移除 PlaneHelper
     // return () => scene.remove(planeHelper);
    }, [clippingPlane, size, color, scene]);
  
    return null;
  }


  function copyRotationAndPositionToObject(clippingPlane, object) {
    const objectQuaternion = new THREE.Quaternion();
    object.getWorldQuaternion(objectQuaternion);

  //  clippingPlane.normal.applyQuaternion(objectQuaternion);
   // clippingPlane.normal.normalize(); // 旋转剪裁平面法线

    const objectPosition = new THREE.Vector3();
    object.getWorldPosition(objectPosition);

    clippingPlane.constant = -objectPosition.dot(clippingPlane.normal); // 调整剪裁平面位置
}


function planerotate(plane,objPos,camPos){

    let n = new THREE.Vector3(); // normal - for re-use
    let cpp = new THREE.Vector3(); //coplanar point - for re-use
    
 
    
    // somewhere in animation loop or anywhere else further
    n.subVectors(camPos, objPos).normalize();
    cpp.copy(objPos);
    plane.setFromNormalAndCoplanarPoint(n, cpp);
}
 


function getBoneQuaternion(bone) {
    const parentWorldQuat = new THREE.Quaternion();
    bone.getWorldQuaternion(parentWorldQuat); // 获取父对象的世界四元数

    // 返回骨骼的四元数旋转值的克隆
    return bone.quaternion.clone();
}


function applyQuaternionToLocalPoint(quaternion, localPoint) {
    // 将四元数旋转应用于局部坐标点
    const rotatedPoint = localPoint.clone().applyQuaternion(quaternion);

    // 返回旋转后的点，即在世界坐标系中的位置
    return rotatedPoint;
}






/**
 * 
 * Load  dress model
 */

function Dressmodel(props){


   // const gltf = useLoader(GLTFLoader, hostloca+'dressModel/2.glb')

    const gltf = useLoader(GLTFLoader, props.url)
    
    const controlRef = useRef();
    const controLRlRef = useRef();
    const controlLSRef = useRef();

    const controlRHRef = useRef();
    const controlRRRef = useRef();
    const controlRSRef = useRef();

    const clippingPlaneRef = useRef();
    const planeHelperRef = useRef();


    


    const { camera } = useThree();
    const ikHelperRef = useRef();

    var skinmeshobj=[]

    //console.log('load model')

    const [showgl, setshowgl] = useState(false);

    //const dressitem= useRef()
    //const dressgroup = useRef()
    const modelRef = useRef();
    const modelRef2 = useRef();
    const bone2ref = useRef();
    const rotabox = useRef();
    const rotabox2 = useRef();
    const rotabox3 = useRef();
    const rotabox4 = useRef();
    const rotabox5 = useRef();
    const rotabox6 = useRef();
    const rotabox7 = useRef();
    const rotabox8 = useRef();
    const rotabox9 = useRef();
    const rotabox10 = useRef();
    const rotabox11 = useRef();
    const rotabox12 = useRef();

    const rotabox13 = useRef();
    const rotabox14 = useRef();
    const rotabox15 = useRef();
    const rotabox16 = useRef();


    const orbitcontroller= useRef();



    const { scene ,gl } = useThree();
    const solverLSRef = useRef();
    const solverRef = useRef();
    const solverLRRef = useRef();

    const solverRSRef = useRef();
    const solverRHRef = useRef();
    const solverRRRef = useRef();

    const solverLTLRef = useRef();
    const solverLTRRef = useRef();
    const solverLFLRef = useRef();
    const solverLFRRef = useRef();


    let helperlr=null
    let helperrh=null
    let helperlh=null
    let helperra=null

    let helperltl =null
    let helperltr =null
    let helperlfl =null
    let helperlfr =null

     

    const ttobj = new THREE.Object3D();

    
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    

   const size = 5; // 假设正方形的半边长为5
   

    // 创建四个剪裁平面，每个平面的法线向内
    const clipPlanes = [
        new THREE.Plane( new THREE.Vector3( 1, 0, 0 ), 0 ),
        new THREE.Plane( new THREE.Vector3( 0,0, 1 ), 0 ),
        new THREE.Plane( new THREE.Vector3( -1, 0, 0 ), 0 )
    ];



    clipPlanes[0].constant=13.4
    clipPlanes[1].constant=.5
    clipPlanes[2].constant=13.4

    

    //clippingPlane[0].constant = .2;
   
    
   
   
   // ClippingPlaneHelper(clipPlanes, 40, 0xff0000 )
   
    useEffect(() => {
       
         
        const loadmodel = gltf.scene;
        arm=[]
         modelArmature=[]


        if (gltf.scene) { // 确保gltf.scene已经加载

            let boneIndex = 0;
             // 遍历模型的所有子对象，并为它们的材质设置剪裁平面
             
       gltf.scene.traverse((child) => {

           
            if (child.isMesh && child.material && child.isSkinnedMesh && skinmeshobj.length<1) {


                const clippingPlane = [new Plane(new THREE.Vector3(0, 0, 1), 0)];
                clippingPlaneRef.current=clippingPlane
                 // 计算新的剪裁平面法线
               if(tre!=null){
                 //  var rotay=RotaC.y 
                 const quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), tre);
                 const initialNormal = new THREE.Vector3(1, 0, 0); // 假设初始法线向量
                 const newNormal = initialNormal.applyQuaternion(quaternion);
               // console.log(RotaC.y)
                 // 更新剪裁平面的法线向量
                 clippingPlaneRef.current[0].normal.set(newNormal.x, newNormal.y, newNormal.z);
                console.log('rotate clip: '+tre)
             } 
          


            // 如果是网格对象，为其材质设置剪裁平面
            child.material.clippingPlanes = clippingPlaneRef.current;
            child.material.clipIntersection = false
            child.material.alphaToCoverage =true
            child.material.clipShadows = false; // 如果需要，也可以让阴影支持剪裁

   
                console.log(child.skeleton,child.name);
                skinmeshobj.push(child.name)


                if(testmode){

                    var color = new THREE.Color( 0xffffff );
                    color.setHex( Math.random() * 0xffffff );
                    const planeHelper = new THREE.PlaneHelper(clippingPlaneRef.current[0], 40, color);
            
                    planeHelperRef.current=planeHelper
                    scene.add(planeHelperRef.current); // 将PlaneHelper添加到场景
                }

          
            }
      
            
            if (child.isBone) {
                console.log(boneIndex++, child.name); // 在控制台打印每个骨骼的索引和名称
              
      
            }



        });    

            // 克隆模型，以避免对原始模型的直接修改
      // const clonedModel = SkeletonUtils.clone(gltf.scene);
      // modelRef.current.add(clonedModel);

       const clonedModel=gltf.scene

       modelRef.current=clonedModel

        scene.add(clonedModel)

 
    
        const ikmodel = clonedModel.getObjectByName(skinmeshobj[0]);
         
        arm[8]=ikmodel

        // 访问Armature
        const armature = clonedModel.getObjectByName('Armature');
        if (armature) {

            //console.log('armature loaded')
            modelArmature[0]=armature
           // modelArmature[0].rotation.z +=Math.PI/2

          // 访问特定骨骼并进行调整
          const BonearmtR = armature.getObjectByName('BoneRarmtop'); // 替换为你的骨骼名称
          const BonearmtL = armature.getObjectByName('BoneLarmtop'); // 替换为你的骨骼名称
          const BonearmbR = armature.getObjectByName('BoneRarmbottom'); // 替换为你的骨骼名称
          const BonearmbL = armature.getObjectByName('BoneLarmbottom'); // 替换为你的骨骼名称  
          const BoneLhand = armature.getObjectByName('BoneLhand'); // 替换为你的骨骼名称  
          const BoneRhand = armature.getObjectByName('BoneRhand'); // 替换为你的骨骼名称  
          const Lhandtarget = armature.getObjectByName('Lhandtarget'); // 替换为你的骨骼名称  
          const Rhandtarget = armature.getObjectByName('Rhandtarget'); // 替换为你的骨骼名称  
          const Larmtarget = armature.getObjectByName('Larmtarget'); // 替换为你的骨骼名称  
          const Rarmtarget = armature.getObjectByName('Rarmtarget'); // 替换为你的骨骼名称  
          const BonesoderL = armature.getObjectByName('Bonesoderleft'); // 替换为你的骨骼名称  
          const BonesoderR = armature.getObjectByName('Bonesoderright'); // 替换为你的骨骼名称  
          const Lsodertarget = armature.getObjectByName('Lsodertarget'); // 替换为你的骨骼名称  
          const Rsodertarget = armature.getObjectByName('Rsodertarget'); // 替换为你的骨骼名称  

          const Legtopltarget = armature.getObjectByName('Legtopltarget'); // 替换为你的骨骼名称  
          const Legfoodltarget = armature.getObjectByName('Legfoodltarget'); // 替换为你的骨骼名称  
          const Legtoprtarget = armature.getObjectByName('Legtoprtarget'); // 替换为你的骨骼名称  
          const Legfoodrtarget = armature.getObjectByName('Legfoodrtarget'); // 替换为你的骨骼名称  


          const Bonehitright = armature.getObjectByName('Bonehitright'); // 替换为你的骨骼名称  
          const Bonehitleft = armature.getObjectByName('Bonehitleft'); // 替换为你的骨骼名称  
          

          if (BonearmtR) arm[0]=BonearmtR
          if (BonearmtL) arm[1]=BonearmtL
          if (BonearmbR) arm[2]=BonearmbR
          if (BonearmbL) arm[3]=BonearmbL
          if (BoneRhand) arm[4]=BoneRhand
          if (BoneLhand) arm[5]=BoneLhand
          if (Rhandtarget) arm[6]=Rhandtarget
          if (Lhandtarget) arm[7]=Lhandtarget

          if (Larmtarget) arm[9]=Larmtarget
          if (Rarmtarget) arm[10]=Rarmtarget

          if (BonesoderL) arm[11]=BonesoderL
          if (BonesoderR) arm[12]=BonesoderR
          if (Lsodertarget) arm[13]=Lsodertarget
          if (Rsodertarget) arm[14]=Rsodertarget

          if (Legtopltarget) arm[15]=Legtopltarget
          if (Legtoprtarget) arm[16]=Legtoprtarget
          if (Legfoodltarget) arm[17]=Legfoodltarget
          if (Legfoodrtarget) arm[18]=Legfoodrtarget


          if (Bonehitright) arm[19]=Bonehitright
          if (Bonehitleft) arm[20]=Bonehitleft


       //   const axesHelper = new THREE.AxesHelper(5); // 参数5定义了轴的大小
        //  Larmtarget.add(axesHelper); // 将它添加到骨骼或对象中
 
         
       
       //  arm[0].rotation.order="YZX";
      
        }
      
       
        
    
        dressalopa=0
        props.loadupdate()

        
          
              
                  rotabox3.current.position.set(5,10,0)
                  rotabox4.current.position.set(5,5,0)

                
                 rotabox5.current.position.set(-5,10,0)
                 rotabox6.current.position.set(-5,5,0)

                 rotabox9.current.position.set(-5,15,0)
                 rotabox10.current.position.set(5,15,0)

                 rotabox13.current.position.set(-5,-8,0)
                 rotabox14.current.position.set(5,-8,0)

                 rotabox15.current.position.set(-5,-10,0)
                 rotabox16.current.position.set(5,-10,0)


                  

                 //  Objquat(bone2ref.current,0,Math.PI/2,Math.PI/3)

               //  bone2ref.current.rotation.y=Math.PI/2
               //  bone2ref.current.rotation.z=Math.PI/4

               //transformcontrols= new TransformControls(camera, gl.domElement)
                // 应用 IK 解算器
                if(arm[8] != undefined && arm[8] != null){

                                        
                //Lsoder====================================================== 
                if(Tfcontroller){
                const controlsLS = new TransformControls(camera, gl.domElement);
                controlsLS.size=.5
                controlsLS.space='world'
                controlsLS.showZ=false
                controlLSRef.current = controlsLS;
                // 将 TransformControls 添加到场景中
                scene.add(controlsLS);
                // 将 TransformControls 绑定到一个对象
                controlsLS.attach(rotabox9.current);
              controlLSRef.current.addEventListener('change', updateIKls);
                }

                const iksls = [
                    {
                    target:12,//Lsodertarget
                    effector: 4,//Larmtop
                    links: [
                        { index: 3,//Bonel soder 
                             }, 
                    ],
                    },
                ];
 
                
                  const solverls = new CCDIKSolver(arm[8], iksls);
                  solverLSRef.current = solverls;
                 // 可视化 IK 链（可选）
                 const helperls = new CCDIKHelper(arm[8], iksls);
                 if(testmode)scene.add(helperls);
 
               
               //Lhand====================================================== 
               if(Tfcontroller){
                // 创建 TransformControls 实例
                const controls = new TransformControls(camera, gl.domElement);
                controls.size=.5
                controls.space='world'
                controls.showZ=false
                controlRef.current = controls;
                // 将 TransformControls 添加到场景中
                scene.add(controls);

                // 将 TransformControls 绑定到一个对象
                 controls.attach(rotabox6.current);
                 controlRef.current.addEventListener('change', updateIKlh);
                }

     
       
                        //   arm[8].add(arm[8].skeleton.bones[0])

                             // 创建 IK 链
                            const iks = [
                                {
                                target:16,//Lhandtarget
                                effector: 6,//lhand
                                links: [
                                    
                                    { index: 5, //larmbottom
                                         }, // Larmdown 索引   { index: 6 }, // Lhand 索引先不添加
                                    
                                ],
                                },
                            ];



                           
                       // console.log(arm[8].skelenton)
                        const solver = new CCDIKSolver(arm[8], iks);
                        solverRef.current = solver;
                       
                         //solverRef.current.update()

                       // 可视化 IK 链（可选）
                         helperlh = new CCDIKHelper(arm[8], iks);
                         if(testmode)scene.add(helperlh);
                      
                      //Larm====================================================== 
                      if(Tfcontroller){
                        const controlsLR = new TransformControls(camera, gl.domElement);
                        controlsLR.size=.5
                        controlsLR.space='world'
                        controlsLR.showZ=false
                        controLRlRef.current = controlsLR;

                        // 将 TransformControls 添加到场景中
                        scene.add(controlsLR);
                        // 将 TransformControls 绑定到一个对象
                        controlsLR.attach(rotabox5.current);
                    
                        controLRlRef.current.addEventListener('change', updateIKla);
                      }


                        const ikslr = [
                            {
                            target:14,//Larmtarget
                            effector: 5,//Larmbottom
                            links: [
                                
                                { index: 4, //Larmtop
                                     }, // Larmdown 索引   { index: 6 }, // Lhand 索引先不添加
                                
                            ],
                            },
                        ];


                        
                          // console.log(arm[8].skelenton)
                          const solverlr = new CCDIKSolver(arm[8], ikslr);
                          solverLRRef.current = solverlr;
                          // solverint=true
                           //solverRef.current.update()
  
                         // 可视化 IK 链（可选）
                          helperlr = new CCDIKHelper(arm[8], ikslr);
                          if(testmode)scene.add(helperlr);

                          //Rsoder====================================================== 
                          if(Tfcontroller){
                            const controlsRS = new TransformControls(camera, gl.domElement);
                            controlsRS.size=.5
                            controlsRS.space='world'
                            controlsRS.showZ=false
                            controlRSRef.current = controlsRS;
                            // 将 TransformControls 添加到场景中
                            scene.add(controlsRS);
                            // 将 TransformControls 绑定到一个对象
                            controlsRS.attach(rotabox10.current);

                            controlRSRef.current.addEventListener('change', updateIKrs);
                          }

                            const iksrs = [
                                {
                                target:11,//Rsodertarget
                                effector: 8,//Rarmtop
                                links: [
                                    { index: 7,//Boner soder 
                                        }, 
                                ],
                                },
                            ];
            
                            
                            const solverrs = new CCDIKSolver(arm[8], iksrs);
                            solverRSRef.current = solverrs;
                            // 可视化 IK 链（可选）
                            const helperrs = new CCDIKHelper(arm[8], iksrs);
                            if(testmode)scene.add(helperrs);
            
                                    

                          //Rhand====================================================== 
                          if(Tfcontroller){
                           // 创建 TransformControls 实例
                            const controlsRh = new TransformControls(camera, gl.domElement);
                            controlsRh.size=.5
                            controlsRh.space='world'
                            controlsRh.showZ=false
                            controlRHRef.current = controlsRh;

                            // 将 TransformControls 添加到场景中
                            scene.add(controlsRh);

                            // 将 TransformControls 绑定到一个对象
                            controlsRh.attach(rotabox4.current);

                            controlRHRef.current.addEventListener('change', updateIKrh);
                          }
                            const iksrh = [
                                {
                                target:15,//Rhandtarget
                                effector: 10,//Rhand
                                links: [
                                    
                                    { index: 9,//Rharmbottom 
                                         }, // Larmdown 索引   { index: 6 }, // Lhand 索引先不添加
                                ],
                                },
                            ];

                              // console.log(arm[8].skelenton)
                            const solverrh = new CCDIKSolver(arm[8], iksrh);
                            solverRHRef.current = solverrh;
                            // solverint=true
                            //solverRef.current.update()
    
                            // 可视化 IK 链（可选）
                             helperrh = new CCDIKHelper(arm[8], iksrh);
                             if(testmode) scene.add(helperrh);




                          //Rarm====================================================== 
                          if(Tfcontroller){
                            // 创建 TransformControls 实例
                            const controlsRa = new TransformControls(camera, gl.domElement);
                            controlsRa.size=.5
                            controlsRa.space='world'
                            controlsRa.showZ=false
                            controlRRRef.current = controlsRa;

                            // 将 TransformControls 添加到场景中
                            scene.add(controlsRa);

                            // 将 TransformControls 绑定到一个对象
                            controlsRa.attach(rotabox3.current);

                            controlRRRef.current.addEventListener('change', updateIKra);
                          }


                            const iksra = [
                                {
                                target:13,//Rarmtarget
                                effector: 9,//Rarmbottom
                                links: [
                                    
                                    { index: 8,//Rarmtop 
                                         }, // Larmdown 索引   { index: 6 }, // Lhand 索引先不添加
                                ],
                                },
                            ];

                              // console.log(arm[8].skelenton)
                              const solverra = new CCDIKSolver(arm[8], iksra);
                              solverRRRef.current = solverra;
                              // solverint=true
                              //solverRef.current.update()
      
                              // 可视化 IK 链（可选）
                               helperra = new CCDIKHelper(arm[8], iksra);
                             
                               if(testmode)scene.add(helperra);


                               
                                 //LegL====================================================== 

                                 
                                  

                                 const iksltl = [
                                    {
                                    target:21,//Legtopltarget
                                    effector: 19,//Bonelegbottoml
                                    links: [
                                        
                                        { index: 18,//Bonelegtop 
                                             }, 
                                    ],
                                    },
                                ];

                                 const solverltl = new CCDIKSolver(arm[8], iksltl);
                                 solverLTLRef.current = solverltl;
                                  helperltl = new CCDIKHelper(arm[8], iksltl);
                                 if(testmode)scene.add(helperltl);


                                   //LegR====================================================== 
              

                                 const iksltr = [
                                    {
                                    target:27,//Legtoprtarget
                                    effector: 25,//Bonelegbottomr
                                    links: [
                                        
                                        { index: 24,//Bonelegright 
                                             }, 
                                    ],
                                    },
                                ];

                                 const solverltr = new CCDIKSolver(arm[8], iksltr);
                                 solverLTRRef.current = solverltr;
                                  helperltr = new CCDIKHelper(arm[8], iksltr);
                                 if(testmode)scene.add(helperltr);


                                 //LFL=============================================================

                                 if(Tfcontroller){
                                    // 创建 TransformControls 实例
                                    const controlsLfl = new TransformControls(camera, gl.domElement);
                                    controlsLfl.size=.5
                                    controlsLfl.space='world'
                                    controlsLfl.showZ=false
                                   
                                    // 将 TransformControls 添加到场景中
                                    scene.add(controlsLfl);
        
                                    // 将 TransformControls 绑定到一个对象
                                    controlsLfl.attach(rotabox15.current);
        
                                    controlsLfl.addEventListener('change', updateIKlfl);
                                  }

                                  const ikslfl = [
                                    {
                                    target:22,//Legfoodltarget
                                    effector: 20,//Bonefootl
                                    links: [
                                        
                                        { index: 19,//Bonelegbottoml 
                                             }, 
                                    ],
                                    },
                                ];

                                 const solverlfl = new CCDIKSolver(arm[8], ikslfl);
                                 solverLFLRef.current = solverlfl;
                                  helperlfl = new CCDIKHelper(arm[8], ikslfl);
                                 if(testmode)scene.add(helperlfl);


                                 //LFR=============================================================

                                 if(Tfcontroller){
                                    // 创建 TransformControls 实例
                                    const controlsLfr = new TransformControls(camera, gl.domElement);
                                    controlsLfr.size=.5
                                    controlsLfr.space='world'
                                    controlsLfr.showZ=false
                                   
                                    // 将 TransformControls 添加到场景中
                                    scene.add(controlsLfr);
        
                                    // 将 TransformControls 绑定到一个对象
                                    controlsLfr.attach(rotabox16.current);
        
                                    controlsLfr.addEventListener('change', updateIKlfr);
                                  }

                                  const ikslfr = [
                                    {
                                    target:28,//Legfoodrtarget
                                    effector: 26,//Bonefootr
                                    links: [
                                        
                                        { index: 25,//Bonelegbottomr 
                                             }, 
                                    ],
                                    },
                                ];

                                 const solverlfr = new CCDIKSolver(arm[8], ikslfr);
                                 solverLFRRef.current = solverlfr;
                                  helperlfr = new CCDIKHelper(arm[8], ikslfr);
                                 if(testmode)scene.add(helperlfr);








                              helperlr.size=0.3
                              helperrh.size=0.3
                              helperlh.size=0.3
                              helperra.size=0.3




                             

                             solverint=true

                            //延时更新避免数据不同步
                             setTimeout(() => {
                                updateik=0
                                console.log('update ik')
                             }, 200);

                           
                          

                       }
         
                   
                    }
         
 

            /*
         return () => {
            if (ikHelperRef.current) {
             // model.remove(ikHelperRef.current);
            }
          }
         */
            // 清理事件监听器
            return () => {
                console.log('remove sth')

                
               // scene.remove(clonedModel)

              // modelRef.current.material.clippingPlanes = [];
 
              
              scene.remove(arm[8])

              arm[8].traverse((child) => {
                if (child.isMesh && child.material.clippingPlanes) {
                  child.material.clippingPlanes = [];
                  child.material.needsUpdate = true;
                  console.log('remove clippingPlanes')
                }
              });

                scene.remove(modelRef.current)

                modelRef.current.traverse((child) => {
                    if (child.isMesh && child.material.clippingPlanes) {
                      child.material.clippingPlanes = [];
                      child.material.needsUpdate = true;
                      console.log('remove clippingPlanes')
                    }
                  });
               
                if(planeHelperRef.current){
                    scene.remove(planeHelperRef.current);
                }
                    scene.remove( clippingPlaneRef.current[0]);
                    clippingPlaneRef.current=null
                   
                
                if(Tfcontroller){
                   
                   // scene.remove(modelRef2.current)
                if (controlRef.current) {
                    controlRef.current.detach(rotabox6.current)
                    controlRef.current.removeEventListener('change', updateIKlh);
                    controlRef.current.dispose ()
                    scene.remove(controlRef.current)
                }
                if (controLRlRef.current) {
                    controLRlRef.current.detach(rotabox5.current)
                    controLRlRef.current.removeEventListener('change', updateIKla);
                    controLRlRef.current.dispose ()
                    scene.remove(controLRlRef.current)
                }
                if (controlRHRef.current) {
                    controlRHRef.current.detach(rotabox4.current)
                    controlRHRef.current.removeEventListener('change', updateIKrh);
                    controlRHRef.current.dispose ()
                    scene.remove(controlRHRef.current)
                }
                if (controlRRRef.current) {
                    controlRRRef.current.detach(rotabox3.current)
                    controlRRRef.current.removeEventListener('change', updateIKra);
                    controlRRRef.current.dispose ()
                    scene.remove(controlRRRef.current)
                }
                if (controlLSRef.current) {
                    controlLSRef.current.detach(rotabox9.current)
                     controlLSRef.current.removeEventListener('change', updateIKls);
                     scene.remove(controlLSRef.current)
                }
                if (controlRSRef.current) {
                    controlRSRef.current.detach(rotabox10.current)
                    controlRSRef.current.removeEventListener('change', updateIKrs);
                    scene.remove(controlRSRef.current)
               }

               
                 

               

                solverRRRef.current =null
                solverRHRef.current =null
                if(helperra)scene.remove(helperra);
                if(helperrh)scene.remove(helperrh);

                if(helperlr)scene.remove(helperlr);
                solverLRRef.current  =null

                if(helperlh) scene.remove(helperlh);
                solverRef.current=null

                if(helperltl) scene.remove(helperltl);
                solverLTLRef.current=null
                

            }
            };
            



      }, [gltf,scene]);


      function solverupdate(bonetag,totarget,mtype){
          // console.log('move controls')
       if(solverint){

          

            

        // 获取骨骼的世界矩阵
        const boneWorldMatrix = bonetag.matrixWorld;

        // 计算世界矩阵的逆
        const inverseBoneWorldMatrix = new THREE.Matrix4().invert();

         /*
          // 绕 X 轴旋转 90 度
            boneWorldMatrix.makeRotationZ(Math.PI / 2);

            // 绕 Y 轴旋转 90 度
            const matrixY = new THREE.Matrix4().makeRotationX(-Math.PI / 2);
            boneWorldMatrix.multiply(matrixY); // 将 Y 轴的旋转应用到矩阵上

            const inverseBoneWorldMatrix = boneWorldMatrix.clone().invert();
       */
          //  const inverseBoneWorldMatrix = new THREE.Matrix4().copy(boneWorldMatrix).invert();

           
        // 创建一个新的 Vector3 来存储局部坐标
       let localPosition =  totarget.position.clone().applyMatrix4(inverseBoneWorldMatrix);
       // let localPosition =  rotabox5.current.position

       // 提取骨骼对象的世界缩放因子
        const worldScale = new THREE.Vector3().setFromMatrixScale(boneWorldMatrix);

        //目标对象中心偏移
        let shitcenterx=-matchPose[0].x
        var netbodysq=matchPose[3]*10
       // var newsq=(1-window.innerWidth/window.innerHeight)
        var newsq=.5
        if(matchPose[3]<0.064)netbodysq=matchPose[3]*9
        let shitcentery=matchPose[0].y-matchPose[0].y*matchPose[1]*newsq
        localPosition.x =(localPosition.x)+shitcenterx
        localPosition.y =(-localPosition.y)+shitcentery
        localPosition.z =(0)

       // console.log(window.innerWidth/window.innerHeight,matchPose[3])
        
      
        
        // 应用逆缩放因子到局部位置
         localPosition = localPosition.divide(worldScale);

         

 
        if(mtype=='lh' || mtype=='la'  || mtype=='rh' || mtype=='ra' || mtype=='ls' || mtype=='rs'){
       //   bonetag.position.set(localPosition.z,-localPosition.x,localPosition.y)
        //   bonetag.position.set(localPosition.z,localPosition.x,-localPosition.y) 
          bonetag.position.set(localPosition.z,-localPosition.y,-localPosition.x)    
      
            }else if(mtype=='ltl' ||  mtype=='lfl' ){
               
               bonetag.position.set(localPosition.z,-localPosition.x,-localPosition.y)     
        
            }else if( mtype=='ltr' ||  mtype=='lfr'){
               
                bonetag.position.set(localPosition.z,localPosition.x,-localPosition.y)     
         
             }
           // solverRSRef.current.update()
           //  solverLSRef.current.update()

            solverLRRef.current.update()
            solverRef.current.update()

            solverRHRef.current.update()
            solverRRRef.current.update()

            solverLTLRef.current.update()
            solverLTRRef.current.update()

            solverLFLRef.current.update()
            solverLFRRef.current.update()
                
       }
      }
      function updateIKlh(){
        solverupdate(arm[7],rotabox6.current,'lh')
      }
      function updateIKla(){
        
         solverupdate(arm[9],rotabox5.current,'la')
      }
      function updateIKrh(){
        solverupdate(arm[6],rotabox4.current,'rh')
      }
      function updateIKra(){
         solverupdate(arm[10],rotabox3.current,'ra')
      }
      function updateIKls(){
        solverupdate(arm[13],rotabox9.current,'ls')
      }
      function updateIKrs(){
        solverupdate(arm[14],rotabox10.current,'rs')
      }
      function updateIKltl(){
        solverupdate(arm[15],rotabox13.current,'ltl')
     }
     function updateIKltr(){
        solverupdate(arm[16],rotabox14.current,'ltr')
     }
     function updateIKlfl(){
        solverupdate(arm[17],rotabox15.current,'lfl')
     }
     function updateIKlfr(){
        solverupdate(arm[18],rotabox16.current,'lfr')
     }

     

       useFrame((state, delta) => {

        
         if(detector==true == showgl==false){
            setshowgl(true)

         }

         if(!modelRef.current)return

        if(matchPose.length>0 && modelArmature.length>0){

           
           
            let scaleC=matchPose[1]*.31  //.31

           //  console.log(matchPose[1]*.31 )
            // let scaleC=.2
            let PosC=matchPose[0]
            let RotaC=matchPose[2]

           
           
            modelRef.current.scale.set(scaleC,scaleC,scaleC)
           
          modelArmature[0].rotation.set(0,RotaC.y,0)   

          //modelRef.current.rotation.set(0,RotaC.y,0)   

           modelRef.current.position.set(PosC.x,PosC.y*1.0,0)   //位置设定

      
       
          

          // if(updateik<1){
            
 
          
            //
           // camera.lookAt(modelRef.current)

            if(clippingPlaneRef.current[0]){
              // console.log(clippingPlaneRef.current)
             // 定义旋转角度，这里每帧旋转0.01弧度
            
             // 计算新的剪裁平面法线
             if(tre==null){
                tre =RotaC.y 
                console.log('rotate: '+RotaC.y)
            } 
           //  var rotay=RotaC.y 
                const quaternion = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), tre);
                const initialNormal = new THREE.Vector3(1, 0, 0); // 假设初始法线向量
                const newNormal = initialNormal.applyQuaternion(quaternion);
              // console.log(RotaC.y)
                // 更新剪裁平面的法线向量
              clippingPlaneRef.current[0].normal.set(newNormal.x, newNormal.y, newNormal.z);

           // console.log(rotay,clippingPlaneRef.current[0].normal.y,newNormal)
            
            
        }
      // }

            
           
            
            // clippingPlane.rotation.y +=.2
            // clippingPlane.current.position.set(PosC.x,PosC.y,0)

            const cliprotation = { x: RotaC.x, y: RotaC.y, z: RotaC.z }; // 旋转角度（弧度）

            // moveAndRotateClippingPlane(clippingPlane, PosC.x, PosC.y, 0, cliprotation);

           // copyRotationAndPositionToObject(clippingPlane,modelArmature[0])



            
         //   planerotate(clippingPlane,rotabox5.current.position,rotabox6.current.position)


            if(arm.length>0 ){

                //缩放及位移偏移转化
                var scalev=matchPose[1]
                var shitmove=matchPose[0]
               
             
                var upsoder=matchPose[4]
                const world_pointR1=new THREE.Vector3( setPosedataToWorld[1].x*scalev+shitmove.x,setPosedataToWorld[1].y*scalev+shitmove.y*upsoder,setPosedataToWorld[1].z*scalev+shitmove.z) //Rs
                const world_pointL2=new THREE.Vector3( setPosedataToWorld[2].x*scalev+shitmove.x,setPosedataToWorld[2].y*scalev+shitmove.y*upsoder,setPosedataToWorld[2].z*scalev+shitmove.z) //Ls

                const world_pointR3=new THREE.Vector3( setPosedataToWorld[3].x*scalev+shitmove.x,setPosedataToWorld[3].y*scalev+shitmove.y,setPosedataToWorld[3].z*scalev+shitmove.z) //Ra
                const world_pointL4=new THREE.Vector3( setPosedataToWorld[4].x*scalev+shitmove.x,setPosedataToWorld[4].y*scalev+shitmove.y,setPosedataToWorld[4].z*scalev+shitmove.z)//La
               
                const world_pointR5=new THREE.Vector3( setPosedataToWorld[5].x*scalev+shitmove.x,setPosedataToWorld[5].y*scalev+shitmove.y,setPosedataToWorld[5].z*scalev+shitmove.z)//Rh
                const world_pointL6=new THREE.Vector3( setPosedataToWorld[6].x*scalev+shitmove.x,setPosedataToWorld[6].y*scalev+shitmove.y,setPosedataToWorld[6].z*scalev+shitmove.z)//Lh


                const world_pointR7=new THREE.Vector3( matchPose[7].x,matchPose[7].y,0)//Rh
                const world_pointL8=new THREE.Vector3( matchPose[8].x,matchPose[8].y,0)//Lh

                const world_pointR9=new THREE.Vector3( matchPose[5].x,matchPose[5].y,0)//Ra
                const world_pointL10=new THREE.Vector3( matchPose[6].x,matchPose[6].y,0)//La



                const world_pointR11=new THREE.Vector3( matchPose[9].x,matchPose[9].y,0)//Ltr
                const world_pointL12=new THREE.Vector3( matchPose[10].x,matchPose[10].y,0)//Ltl

                const world_pointR13=new THREE.Vector3( matchPose[11].x,matchPose[11].y,0)//Lfr
                const world_pointL14=new THREE.Vector3( matchPose[12].x,matchPose[12].y,0)//Lfl



              //  rotabox.current.position.copy(world_pointR7)
               // rotabox2.current.position.copy(world_pointL8)


                rotabox7.current.position.copy(world_pointR9)
                rotabox8.current.position.copy(world_pointL10)


                rotabox11.current.position.copy(world_pointR1)
                rotabox12.current.position.copy(world_pointL2)


                

           
             
            //   boneLookAt_zfront(arm[0],rotabox.current.position)
           //     boneLookAt_zback(arm[2],  rotabox3.current.position,2)
            //   boneLookAt_zback(arm[1],rotabox2.current.position,1)
            //   boneLookAt_zback(arm[3],rotabox4.current.position,3)
 

               

           
                   
                 


              
              
                if(solverint){
                   // console.log(solverRef.current )
                   if(dressalopa<1){
                    gltf.scene.traverse((child) => {
                        if (child.isMesh && child.material) {
                        // 如果是网格对象，为其材质设置剪裁平面
                        child.material.opacity = dressalopa;
                        
                        }
                        });
                    
                    dressalopa+=.1
                  
                   }

                   if(updateik<2){

                rotabox10.current.position.copy(world_pointR1)  //rs
                rotabox9.current.position.copy(world_pointL2)  //ls
                   
                rotabox3.current.position.copy(world_pointR9) //ra
                rotabox5.current.position.copy(world_pointL10) //la
                rotabox4.current.position.copy(world_pointR7)  //rh
                rotabox6.current.position.copy(world_pointL8)  //lh


                rotabox13.current.position.copy(world_pointL12)  //ltl
                rotabox14.current.position.copy(world_pointR11)  //ltr


                rotabox15.current.position.copy(world_pointL14)  //lfl
                rotabox16.current.position.copy(world_pointR13)  //lfr


                //pose hit position
                const shifhit=(matchPose[13].x-PosC.x)/2

                arm[19].position.z =-shifhit
                arm[20].position.z =-shifhit
     
                rotabox.current.position.set(matchPose[13].x,matchPose[13].y,matchPose[13].z)
                
                 

                     updateik +=1
                     solverupdate(arm[14],rotabox10.current,'rs')
                     solverupdate(arm[13],rotabox9.current,'ls')
                     solverupdate(arm[7],rotabox6.current,'lh')
                     solverupdate(arm[9],rotabox5.current,'la')
                     solverupdate(arm[6],rotabox4.current,'rh')
                     solverupdate(arm[10],rotabox3.current,'ra')

                     solverupdate(arm[15],rotabox13.current,'ltl')
                     solverupdate(arm[16],rotabox14.current,'ltr')

                     solverupdate(arm[17],rotabox15.current,'lfl')
                     solverupdate(arm[18],rotabox16.current,'lfr')


                    
                  
                  // solverRef.current.update()
                 
              
                    scene.traverse(object => {

                    

                        if (object.isSkinnedMesh) {

                        // 确保有 geometry 属性
                        if (object.geometry) {
                            
                           
                            /*
                            const positionArray = object.geometry.attributes.position.array;
                            for (let i = 0; i < positionArray.length; i++) {
                            if (isNaN(positionArray[i])) {
                                console.log(`NaN value found at position ${i}`);
                                return true;
                            }
                            }*/
                        object.geometry.attributes.position.needsUpdate = true;
                                
                            // 为 SkinnedMesh 计算包围球
                        object.geometry.computeBoundingSphere();

                            // 可选：如果你还想更新包围盒
                        // object.geometry.computeBoundingBox();
                        
                        }
                        }
                    });

                }

                }
                



            }

        }

       
       

       })


       return (
        <>

{/*
       <OrbitControls   ref={orbitcontroller} />
       */}
        <group ref={modelRef2} visible={showgl}>

     
           
            <mesh ref={rotabox}  visible={testmode} >
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'red'} opacity={0} wireframe={false}/>
            </mesh>
            <mesh ref={rotabox2}  visible={testmode} >
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'blue'} opacity={0} wireframe={false}/>
            </mesh>

            <mesh ref={rotabox7}  visible={testmode} >
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'blue'} opacity={0} wireframe={false}  />
            </mesh>
            <mesh ref={rotabox8}  visible={testmode} >
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'blue'} opacity={0} wireframe={false} />
            </mesh>




            <mesh ref={rotabox3}   visible={testmode}>
                <boxGeometry args={[1,1,1]} />
                <meshStandardMaterial color={'yellow'} opacity={0} wireframe={false}/>
            </mesh>
            <mesh ref={rotabox4}   visible={testmode}>
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'yellow'} opacity={0} wireframe={false}/>
            </mesh>


            <mesh ref={rotabox5}  visible={testmode} >
                <boxGeometry args={[1,1, 1]} />
                <meshStandardMaterial color={'yellow'} opacity={0} wireframe={false}  />
            </mesh>
            <mesh ref={rotabox6}  visible={testmode} >
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'yellow'} opacity={0} wireframe={false} />
            </mesh>


            <mesh ref={rotabox9}  visible={testmode} >
                <boxGeometry args={[1,1, 1]} />
                <meshStandardMaterial color={'pink'} opacity={0} wireframe={false}  />
            </mesh>
            <mesh ref={rotabox10}  visible={testmode} >
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'pink'} opacity={0} wireframe={false} />
            </mesh>

            <mesh ref={rotabox11}  visible={testmode} >
                <boxGeometry args={[1,1, 1]} />
                <meshStandardMaterial color={'green'} opacity={0} wireframe={false}  />
            </mesh>
            <mesh ref={rotabox12}  visible={testmode} >
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'green'} opacity={0} wireframe={false} />
            </mesh>

            <mesh ref={rotabox13}  visible={testmode} >
                <boxGeometry args={[1,1, 1]} />
                <meshStandardMaterial color={'blue'} opacity={0} wireframe={false}  />
            </mesh>
            <mesh ref={rotabox14}  visible={testmode} >
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'blue'} opacity={0} wireframe={false} />
            </mesh>

            <mesh ref={rotabox15}  visible={testmode} >
                <boxGeometry args={[1,1, 1]} />
                <meshStandardMaterial color={'blue'} opacity={0} wireframe={false}  />
            </mesh>
            <mesh ref={rotabox16}  visible={testmode} >
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'blue'} opacity={0} wireframe={false} />
            </mesh>

        

        
             {/*
       <group {...props}  ref={dressgroup}   >

       <primitive ref={dressitem} object={gltf.scene} />

  
        </group>
       */}
        </group>
        </>
       
       )
   


}


/**
 * capture
 */
let call_capt=0
let capt=0

function CaptureCanvas(props) {
    const { gl } = useThree(); // 获取 WebGLRenderer 实例

    
  
    useEffect(() => {
      
    }, [gl]);

    useFrame((state, delta,scene) => {


          if(call_capt==1){
            
          if(capt==0){
            capt=1
           

                    // 确保 gl.domElement 是存在的
            if (gl && gl.domElement) {
                console.log('set capture' + gl.domElement);

                

                const canvas = gl.domElement; // 实际的 <canvas> 元素
                const dataURL = canvas.toDataURL(); // 截取画面
        
               // console.log(dataURL); // 使用或显示 dataURL

               setTimeout(() => {
                capturedata=dataURL
                props.capture()
               }, 1000);
                
                call_capt=0
                capt=0


               
                // 例如，你可以将 dataURL 设置为图像的 src 来显示截图
                // 或者使用其他方法保存或处理这个截图
            }

          }
        }


    })
  
    return null; // 这个组件不渲染任何东西，只执行截图操作
  }


  /**
   * 
   * 匹配四肢是否在指定区域
   */


  function match_area_crop(markpoint,startcroppoinit,endcroppoint){

       let mx=markpoint[0]
       let my=markpoint[1]

       let stcx=startcroppoinit[0]
       let stcy=startcroppoinit[1]

       let endcx=endcroppoint[0]
       let endcy=endcroppoint[1]

       let matchareamark=false
       if(mx>stcx && mx< endcx){

          if(my >stcy && my< endcy){

            matchareamark=true
          }

       }

       return matchareamark

  }



/**
 * Three js scene
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
 
let callbackhome=false
function Scene(props) {
 
    const [loadfinish, setloadfinish] = useState(0);
 
    
    const blackboardRef=useRef(null)
    const glElement=useRef(null)
    const glcontainer=useRef(null)
    const [showGl, setshowGl] = useState(false);
    const [glcontaineralpha, setglcontaineralpha] = useState(1);
    const Loadmanager = new THREE.LoadingManager();
    const textureLoader = new THREE.TextureLoader(Loadmanager);
    const [camstage, setcamstage] = useState(0);
    const [modelurl,setmodelurl]=useState(hostloca+'dressModel/1.glb');
    const [modelkey,setmodelkey]=useState(0)
    const [overlayalpha,setoverlayalpha]=useState(0.8)
    const [overlaydisplay,setoverlaydisplay]=useState('flex')
    
     const [dpr,setdpr]=useState(2)

     const [sundetal,setsundetal]=useState({x:2,y:0,z:5,tur:5,ray:.6})
 
     const w=window.innerWidth

    const pointLightRef =useRef();

    const [isPaused,setisPaused]=useState('always')
    
    const [matcharea,setmatcharea]=useState([[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]])

    const [matchareacor,setmatchareacor]=useState(['white','white','white','white','white'])

    const [showmodel,setshowmodel]=useState(false)

  
    const isMobile=false
    //const texture = useLoader(RGBELoader, 'http://192.168.31.22:3000/data/aerodynamics_workshop_1k.hdr')
   
    THREE.ColorManagement.legacyMode = false

    var kks=0
    var subtitleY=40
    
    
    

    useEffect(() => {



          

 
            console.log('dress id : '+ props.dressid)
            const modelurl=props.data[props.dressid].model
            setmodelurl(hostloca+modelurl)
            setmodelkey(props.dressid)
           setoverlayalpha(.8)
           setoverlaydisplay('flex')

         
         
     
    },[props.dressid]);  

    function reloadupdate(){
        setoverlayalpha(0)
        setoverlaydisplay('none')
        props.loadercallback()
        console.log('gltf reloaded')

    }


    function match_safearea(){

      setmatcharea(true)
      
    //  console.log('match area')
     // console.log(matchPose2d[7][0]+'//'+matchPose2d[7][1])

      setmatcharea([matchPose2d[0],matchPose2d[1],matchPose2d[2],matchPose2d[3],matchPose2d[4],matchPose2d[5],matchPose2d[6],matchPose2d[7],matchPose2d[8]])
      let corarray=Array.from(matchareacor)
      
      


      //match head
      let hstcx=(w-headareasize)/2
      let hency=headareatop

      let headslx=hstcx+headareasize
      let headsly=hency+headareasize
    
       //l hand match

      let handslx=(w-handareasize)/2-handareasize
      let handsly=handtop

      let handelx=handslx+handareasize
      let handely=handsly+handareasize


       //r hand match

       let handsrx=(w-handareasize)/2+handareasize
       let handsry=handtop
 
       let handerx=handsrx+handareasize
       let handery=handsry+handareasize


      //l foot match
      let fandslx=(w-foodareasize)/2
      let fandsly=footareatop

      let fandelx=fandslx+foodareasize
      let fandely=fandsly+foodareasize

      //r foot match =l foot match area

       

      let match_head=match_area_crop(matchPose2d[8],[hstcx,hency],[headslx,headsly])

      if(!match_head){
        corarray[0]='red'
        console.log('match area haed false----------')
        matcharea_state=false
      }else{}


       
     let match_handl=match_area_crop(matchPose2d[3],[handslx,handsly],[handelx,handely])

      if(!match_handl){
        corarray[2]='red'
        console.log('match area hand l false----------')
        matcharea_state=false
      }



      let match_handr=match_area_crop(matchPose2d[2],[handsrx,handsry],[handerx,handery])

      if(!match_handr){
        corarray[1]='red'
        console.log('match area hand r false----------')
        matcharea_state=false
      }




      let match_footl=match_area_crop(matchPose2d[7],[fandslx,fandsly],[fandelx,fandely])

      if(!match_footl){
        corarray[4]='red'
        console.log('match area foot l false----------')
        matcharea_state=false
      }


      let match_footr=match_area_crop(matchPose2d[6],[fandslx,fandsly],[fandelx,fandely])

      if(!match_footr){
        corarray[3]='red'
        console.log('match area foot r false----------')
        matcharea_state=false
      }

     

      setmatchareacor(corarray)
      reloadupdate()
      if(!callbackhome){
        callbackhome=true
        
        props.macthcallback(matcharea_state)  //call back hide info btn
        if(matcharea_state) setshowmodel(true) //show dress model
      
       
      }
    

       
      
   
    }



   useEffect(()=>{


     if(props.show){
      console.log('show model ====')
      setoverlayalpha(.8)
      setoverlaydisplay('flex')
      setshowmodel(true) //show dress model
      setTimeout(() => {
        setoverlayalpha(0)
        setoverlaydisplay('none')
      }, 1000);

     }

   },[props.show])



    function loadderdone(){

        //console.log('call back')
        
        
        setloadfinish(1)
        props.loadpop(true)

        setglcontaineralpha(0)
        gsap.to(glcontainer.current, {
        opacity: 1, // 旋转360度
        duration: 1, // 持续时间为2秒
        delay:0.3,
        ease: 'power1.inOut' // 缓动函数
       });

        setshowGl(true)


    }

     function callbackcapture(){
        props.capture()
     }
 

   

   
   useEffect(()=>{
    

    if(isAndroid){

       // setdpr(.8)
        Android=true
      
    }

    //console.log('scene loadfinish')
   },[])


  
    



    return(<div ref={glcontainer} style={{width:'100vw',height:'100vh',opacity:glcontaineralpha}} >

           <Canvas id={'threecanvas'} shadows   camera={{ position: [0, 0, 50],fov:50}}    dpr={[1, dpr]}  gl={{ antialias: true,alpha:true ,localClippingEnabled: true,preserveDrawingBuffer:true}} linear   frameloop={isPaused}  style={{width:'100vw',height:'100vh'}}>
 
 
           
          <ambientLight intensity={4}  color="#ffffff"/>

          <pointLight intensity={1} position={[10, 10, 10]} />

          <pointLight intensity={1} position={[-10, 10, 10]} />
 
 
         
 
          <Posescene match_callback={match_safearea} />
          {showmodel? (
          <Dressmodel  url={modelurl} key={modelkey} loadupdate={reloadupdate}  />
          ):('')}
          {testmode? (
           <>
            <Stats /> 
           
            </>
            ):('')}
          
           {/*
         
            <Coordinate />
          
           <OrbitControls    />
          
           */}


          
           <CaptureCanvas capture={callbackcapture}/>

  
           <Environment  resolution={64} files={hostloca+"data/brown_photostudio_01_1k.hdr"}  />
            
 
           </Canvas>

           <div    style={{zIndex:400,pointerEvents:pointer,position:'fixed',width:'100vw',height:'100vh',
               top:0,left:0,backgroundColor:'#222222',textAlign:'center',opacity:overlayalpha,alignItems:'center',color:'#fff',display:overlaydisplay,justifyContent:'center',transition:`all 0.3s`
           }}>  
            <img src={hostloca+'Assets/loading.gif'} style={{width:'20vw',marginTop:'-50vw'}} />
            </div>

            {/*------四肢在画面中相对位置-------*/}
            {testmode?(
              <div>
            <div style={{position:'absolute',width:'5vw',height:'5vw',backgroundColor:matchareacor[0],left:matcharea[8][0],top:matcharea[8][1]}}></div>
       

            <div style={{position:'absolute',width:'5vw',height:'5vw',backgroundColor:matchareacor[1],left:matcharea[2][0],top:matcharea[2][1]}}></div>
            <div style={{position:'absolute',width:'5vw',height:'5vw',backgroundColor:matchareacor[2],left:matcharea[3][0],top:matcharea[3][1]}}></div>
       

            <div style={{position:'absolute',width:'5vw',height:'5vw',backgroundColor:matchareacor[3],left:matcharea[6][0],top:matcharea[6][1]}}></div>
            <div style={{position:'absolute',width:'5vw',height:'5vw',backgroundColor:matchareacor[4],left:matcharea[7][0],top:matcharea[7][1]}}></div>
            </div>
           ):('')}

             {/*-------------*/}
    </div>
    )
}


// 计算iOS Safari不含地址栏的视窗高度
function getViewportHeight() {
    const element = document.createElement('div');
    element.style.position = 'fixed';
    element.style.height = '100vh';
    element.style.width = '1px';
    document.body.appendChild(element);
  
    const viewportHeight = element.offsetHeight;
    document.body.removeChild(element);
  
    return viewportHeight;
  }
  



function Marksafearea(props){

  const [showareainfo,setshowareainfo]=useState(false)

  const pareh=ph

  const footpare=pareh*.2

  const w=window.innerWidth


  const handw=pareh*.12

  const headw=pareh*.08


  useEffect(()=>{

   if(props.show){
     console.log('=================================================================================='+props.show)
    setshowareainfo(true)
   }else{
    setshowareainfo(false)
   }


   if(props.tag=='w'){
   // ph=window.innerHeight*.86
    headareasize=ph*.045
     handareasize=ph*.11
     foodareasize=ph*.16


    headareatop=ph*.15
    handtop=ph*.48
    footareatop=ph-foodareasize*1.1
   }else{

   // ph=window.innerHeight*.86
    headareasize=ph*.045
     handareasize=ph*.1
     foodareasize=ph*.16


    headareatop=ph*.19
    handtop=ph*.48
     footareatop=ph-foodareasize*1.3
   }




  },[props.show])


  function selectphoto(){

   
    window.location.href=hostloca+'select?id='+props.pid+'&st=3'

    //navigate('/home',{ state: { dressid: dressid, step: 2 }});

}


  function continuebtn(){

    setshowareainfo(false)
    props.continuecallback()
  }

return <div>
         
         {testmode?(
          <div >
            <div style={{width:headareasize,height:headareasize,backgroundColor:'green',opacity:.7,left:(w-headareasize)/2,zIndex:200,top:headareatop,position:'absolute',color:'white',justifyContent:'center',display:'flex'}}>
                  head
                </div>


              <div style={{width:handareasize,height:handareasize,backgroundColor:'green',opacity:.7,left:(w-handareasize)/2+handareasize,zIndex:200,top:handtop,position:'absolute',color:'white',justifyContent:'center',display:'flex'}}>
                  handr
                </div>


                <div style={{width:handareasize,height:handareasize,backgroundColor:'green',opacity:.7,left:(w-handareasize)/2-handareasize,zIndex:200,top:handtop,position:'absolute',color:'white',justifyContent:'center',display:'flex'}}>
                  handl
                </div>


                <div style={{width:foodareasize,height:foodareasize,backgroundColor:'green',opacity:.7,left:(w-foodareasize)/2,zIndex:200,top:footareatop,position:'absolute',color:'white',justifyContent:'center',display:'flex'}}>
                  feet
                </div>
              </div>
          ):('')}
      {showareainfo?(

              <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',position:'absolute',top:0,left:0}}>

             
                <img  src={hostloca+'Assets/matcharealine.png'}  style={{width:'75vw',top:window.innerHeight*.8,position:'absolute'}}/>

                <div onClick={()=>selectphoto()} className={'shopbtn'} style={{position:'absolute',top:window.innerHeight*.9,backgroundColor:'black',width:'30vw',height:'7vw',left:'17vw'}}>RETAKE</div>

                <div  onClick={()=>continuebtn()} className={'shopbtn'} style={{position:'absolute',top:window.innerHeight*.9,backgroundColor:'black',width:'30vw',height:'7vw',left:'53vw'}}>CONTINUE</div>
        
        
              </div>


      ):('')}

      </div>



}  

var jsondata=null

export default function Dressup(props) {


    const [jspdata,setjspdata]=useState(null)
    const [intscene,setintscene]=useState(false)
    const [intpose,setintpose]=useState(false)
    const [dressid,setdressid]=useState(0)
    const [capturescreen,setcapturescreen]=useState(false)

    const [showcapture,setshowcapture]=useState(false)
    const [captureurl,setcaptureurl]=useState('')
    const [canbgcolor,setcanbgcolor]=useState('#222')


     const [showinfo,setshowinfo]=useState(false)

     const [showmatcharea,setshowmatcharea]=useState(false)
   

   const [dress_pid,setdress_pid]=useState(0)

   const [showmarkguidew,setshowmarkguidew]=useState('none')
   const [showmarkguidek,setshowmarkguidek]=useState('none')
   
   
    
    let navigate = useNavigate();

    const fileInputRef = useRef(null);


  useEffect(()=>{



    const url = hostloca+"/data/dress.json";


       const fetchData = async () => {
         try {
           const response = await fetch(url);
           const json = await response.json();
           const {results} = json;

            jsondata=json.qadata
             setjspdata(json.qadata)
            // console.log(jsondata)
            
             pid=jsondata[props.dressid].pid
            console.log('get dress id :'+props.dressid + 'pid :'+pid)

            setdress_pid(pid)

             setdressid(props.dressid)
           console.log('int tensorflow ')
           setintpose(true) 
            

         } catch (error) {
           console.log("error", error);
         }
       };
   
         fetchData();
    },[props])

    function posecallback(e){
        //console.log('get pose')
        setPosedata=e

       
      
    
      }

    function tfcallback(e){

            detector=true
            console.log('show gl')

            if(intscene==false && setPosedata.length>0 && sceneload==false){
                sceneload=true
                console.log('int scene ')
                
                setcanbgcolor('#dbd8ce')
                setintscene(true)
            }
           
       
    } 


    function loaderdress(e){
     console.log('loader running block drag action')

    }


    function matcharea(e){
        
       console.log('match area state '+ e)
       if(e){
        setshowinfo(true)
        setshowmatcharea(false)
       
       
        setshowmarkguidew('none')
        setshowmarkguidek('none')
       }else{
        setshowinfo(false)
        setshowmatcharea(true)
       
        if(props.tag=='w'){
          setshowmarkguidew('flex')
        }else{
          setshowmarkguidek('flex')
        }

       }
    }



    function continuedress(){

      console.log('continu  edress up')
      setshowmarkguidew('none')
      setshowmarkguidek('none')
      setshowinfo(true)

    }

    function clothsize(e){

        let sizearr=[.94,.97,1,1.04,1.08]
          setclothsize=sizearr[e]
          console.log('change cloth size : '+ setclothsize)
          sizeid=e
          dressalopa=0

    }

    function Uicallback(e){
       
        setdressid(e)
       deressid=e
       pid=jsondata[e].pid
       console.log('choose dressid :'+e+ ' pid :'+pid )
    }

    function canceltryon(){
        setintscene(false)
        setintpose(false)
        //navigate('/select');
       // console.log(hostloca+'select')
        window.location.href=hostloca+'select?id='+pid+'&st=2'

       
    }

    function selectphoto(){

         
        setintscene(false)
        setintpose(false)
        window.location.href=hostloca+'select?id='+pid+'&st=3'

        //navigate('/home',{ state: { dressid: dressid, step: 2 }});

    }


    function resizeAndCaptureCanvas() {
        // 创建一个新的 canvas 元素

        const originalCanvas = document.getElementById('posecanvas');
        const originalContext = originalCanvas.getContext('2d');


        console.log(originalCanvas.height + '/' + window.innerHeight)

        const resizedCanvas = document.createElement('canvas');
        const resizedContext = resizedCanvas.getContext('2d');

        // 计算新 canvas 的尺寸，保持原始宽高比，高度匹配浏览器窗口高度*0.86
        const browserHeight =ph;
        const scale = browserHeight /originalCanvas.height;
        const resizedWidth =  originalCanvas.width  * scale;
        const resizedHeight = browserHeight;

        // 设置新 canvas 的尺寸
        resizedCanvas.width = resizedWidth;
        resizedCanvas.height = resizedHeight;

        // 将原始 canvas 的内容按比例绘制到新 canvas 上
        resizedContext.drawImage(originalCanvas, 0, 0, resizedWidth, resizedHeight);

        // 将新 canvas 的内容转换成图片数据 URL
         const imageURL = resizedCanvas.toDataURL('image/png');

        // 以下是使用或保存 imageURL 的示例代码
        return(imageURL); // 或者将 imageURL 设置为 <img> 元素的 src，或者通过链接下载

      //  return(originalContext) 
    }

     
    function callbackcapture(e){
       
      //  console.log(capturedata); // 使用或显示 dataURL
      //  console.log('save done')

       
      /*
       // setcaptureurl(capturedata)
       // setshowcapture(true)
 
       */

       
       const poseimg = document.createElement('img');
       poseimg.src = resizeAndCaptureCanvas()

       const threeimg = document.createElement('img');
       threeimg.src = capturedata;

      
        
      
        
        poseimg.onload = () => {
            threeimg.onload = () => {


            console.log('save done')
            // 创建一个新的 canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');


            const realwinheight=getViewportHeight()
            const realwinwidth=window.innerWidth;

            // 设置 canvas 的大小为第一张图像的大小（或者根据需求调整）
            canvas.width =realwinwidth
            canvas.height =realwinheight

            const imx=(realwinwidth- poseimg.width)/2


            // 首先绘制第一张图像
            ctx.drawImage(poseimg, imx, 0, poseimg.width, poseimg.height);

             // 计算第二张图像保持比例调整后的宽度
           // const scale = window.innerHeight / threeimg.height;
           // const scale =1;
             const resizedWidth2 =realwinheight*threeimg.width /threeimg.height;
             const resizedHeight2 = realwinheight; // 与 screen 的高度相同


            

            console.log(resizedHeight2+'/'+resizedWidth2)

            // 计算第二张图像的位置，以确保水平居中
            const x2 = (realwinwidth - resizedWidth2) / 2;
            const y2 = 0; // 假设从顶部开始绘制

            // 绘制第二张图像
            ctx.drawImage(threeimg, x2, y2, resizedWidth2, resizedHeight2);

            const finalImageURL = canvas.toDataURL('image/png');

                // 创建一个 <a> 元素
                const link = document.createElement('a');
                // 设置链接的 href 为 data URL
                link.href = finalImageURL;
                // 设置下载的文件名
                link.download = 'capture.png';
                // 触发点击事件进行下载
                link.click();
             
        }

     }; 

     
     

    }
    

    function capturesphoto(){
        console.log('save img')

      
       call_capt=1
         
    }

 

    return(

        <div>

                <div style={{position: 'fixed',textAlign:'center',backgroundColor:canbgcolor,width:'100vw',height:'100vh',top:0,verticalAlign:"center",display:'flex',alignItems:"center",display:'block'}}>

              {/*
                <BlazePoseWebcam  poseData={posecallback} />

         */}
              {intpose? (
                 <BlazePosePhoto  poseData={posecallback} callback={tfcallback} photourl={props.photourl} testmode={testmode}/>
                 ):('')}
                 </div>
            


            {intscene? (
           
                <div>
                      <Scene data={jsondata}  dressid={dressid}  capture={callbackcapture} macthcallback={matcharea}  show={showinfo} loadercallback={loaderdress}/>


                 <SlidingButtons data={jsondata} callback={Uicallback} dressid={dressid}  show={showinfo} tag={props.tag}/>


                 <div style={{pointerEvents:pointer,width:'100vw',height:'100vh',position:'absolute',backgroundColor:'white',opacity:1,left:0,top:window.innerHeight*.80}}>  </div>


                 <Dressinfo data={jsondata} dressid={dressid} key={dressid} capturesphoto={capturesphoto} clothsize={clothsize} sizeid={sizeid} show={showinfo} />

                 <img onClick={()=>selectphoto()} src={hostloca+'Assets/changephoto.png'} style={{position:'fixed',width:'30vw',top:'1vh',left:'5vw'}} />   


                 <img  onClick={()=>canceltryon()} src={hostloca+'Assets/cancelbtn.png'} style={{position:'fixed',width:'10vw',top:'2.5vh',right:'5vw'}} />   


                 <img  src={hostloca+'Assets/fixpose3.png'} style={{display:showmarkguidew,position:'fixed',height:ph,top:0,left:(window.innerWidth-1080/1920*ph)/2,pointerEvents:'none'}} />
                 <img  src={hostloca+'Assets/fixpose2.png'} style={{display:showmarkguidek,position:'fixed',height:ph,top:0,left:(window.innerWidth-1080/1920*ph)/2,pointerEvents:'none'}} />
                      
    

                  


                 <Marksafearea show={showmatcharea} continuecallback={continuedress} pid={dress_pid}  tag={props.tag}/>
                 
                 </div>
           ):('')}

           
            {showcapture? (
              <div style={{pointerEvents:pointer,position: 'fixed',textAlign:'center',width:'100vw',height:'100vh',top:0,verticalAlign:"center",display:'flex',alignItems:"center",display:'block'}}>

                <div style={{width:'100vw',height:'100vh',position:'absolute',backgroundColor:'#111',opacity:.76}}/>
                <img src={captureurl} style={{width:'100vw'}} />

              </div>

             ):('')}

        </div>


    )

}

  