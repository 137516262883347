import {BrowserRouter as Router,Routes,Route,Redirect} from 'react-router-dom';
import React, { Suspense, useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { BrowserView, MobileView, isBrowser, isMobile ,isAndroid} from 'react-device-detect';

import './App.css';
import Dressing from './Home/Dressing';
import Select from './Home/Select';
import Desktop from './Home/Desktop';
import RotatingModel from './Test/RotationModel'; 

function App() {

  const [usemb,setusemb]=useState(true)

 useEffect(()=>{

 if(isMobile){
   if(window.innerHeight>window.innerWidth){

    setusemb(true)
   }else{
    setusemb(false)
   }

 }else{
    setusemb(false)

 }

 },[])

  
  return (
      <Router>

        <div className='mainContentBox' >
          <Routes>

           
           
            {usemb?(

            <Route   path="/" element={<Select />} />
             ):(
              
              <Route   path="/" element={<Desktop />} />

             ) } 
             
            <Route   path="/dt" element={<Desktop />} />
            <Route   path="/dressing" element={<Dressing />} />
            <Route   path="/select" element={<Select />} />
            <Route   path="/test" element={<RotatingModel />} />

          </Routes>
        </div>

       

      </Router>
  );
}

export default App;
