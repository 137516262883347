// ChatbotWindow.js
import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

function ChatbotWindow({ selectedDress, userInput }) {
  const [inputText, setInputText] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [userToken, setUserToken] = useState("");
  const chatContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // 组件挂载时生成用户token
    const token = uuidv4();
    setUserToken(token);
    // 可以选择将token存储在localStorage中,以便在页面刷新后保持相同的token
    localStorage.setItem("userToken", token);
  }, []);
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSubmit = async (e) => {
    setInputText("");

    e.preventDefault();
    if (inputText.trim() !== "") {
      setIsLoading(true);
      const newMessage = {
        text: inputText,
        isUser: true,
      };
      await new Promise((resolve) => {
        setChatHistory((prevHistory) => [...prevHistory, newMessage]);
        resolve();
      });

      try {
        let info = "";
        if (selectedDress) {
          info += `User is looking item ${selectedDress.item}.`;
        }
        if (userInput) {
          info += `User's personal information [${userInput}].`;
        }
        const response = await sendMessageToAPI(inputText, info);
        await new Promise((resolve) => {
          setChatHistory((prevHistory) => [
            ...prevHistory,
            { text: response, isUser: false },
          ]);
          resolve();
        });
      } catch (error) {
        console.error("Error sending message:", error);
        await new Promise((resolve) => {
          setChatHistory((prevHistory) => [
            ...prevHistory,
            { text: "Error: Unable to get response", isUser: false },
          ]);
          resolve();
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const sendMessageToAPI = async (message, info) => {

    // http://localhost:8001/chatGPT/balou
    // https://ai-uk.asssm.com/chatGPT/balou
    const response = await fetch("https://ai-uk.asssm.com/chatGPT/balou", {

      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userToken: userToken,
        question: message,
        userInfo: info,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.text.value; // 假设API返回的JSON中包含一个'response'字段
  };

  // 添加一个新的辅助函数来处理文本
  const processText = (text) => {
    // 匹配 [描述](URL) 格式的链接
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;

    return text
      .split(linkRegex)
      .map((part, index) => {
        if (index % 3 === 1) {
          // 这是链接描述
          const description = part;
          const url = text.split(linkRegex)[index + 1];
          return (
            <a
              key={index}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              {description}
            </a>
          );
        } else if (index % 3 === 0) {
          // 这是普通文本
          return part;
        }
        // index % 3 === 2 的情况是 URL，我们已经在描述部分使用了它，所以这里不需要返回任何内容
        return null;
      })
      .filter(Boolean); // 过滤掉 null 值
  };

  const typingAnimationStyle = {
    display: 'inline-block',
    width: '50px',
    height: '20px',
    position: 'relative',
  };
  
  const dotStyle = {
    position: 'absolute',
    top: '10px',
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: '#999',
    animation: 'typingAnimation 1.5s infinite',
  };
  
  const typingKeyframes = `
    @keyframes typingAnimation {
      0% { opacity: 0; }
      25% { opacity: 1; }
      50% { opacity: 1; }
      75% { opacity: 1; }
      100% { opacity: 0; }
    }
  `;

  return (
    <div
      key={chatHistory.length}
      style={{
        backgroundColor: "#f8f8f8",
        padding: "1rem",
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        height: "400px",
      }}
    >
      <style>{typingKeyframes}</style>
      <h3
        style={{
          marginBottom: "0.5rem",
          marginTop: "0.1rem",
          textAlign: "center",
          fontSize: "1.5rem",
        }}
      >
        AI Advisor
      </h3>
      <div
        style={{
          height: "1px",
          backgroundColor: "#e0e0e0",
          margin: "0 0rem 1rem",
        }}
      ></div>
      <div
        ref={chatContainerRef}
        style={{
          flex: 1,
          overflowY: "auto",
          marginBottom: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {chatHistory.map((chat, index) => (
          <div
            key={index}
            style={{
              alignSelf: chat.isUser ? "flex-end" : "flex-start",
              backgroundColor: chat.isUser ? "#007bff" : "#e5e5ea",
              color: chat.isUser ? "white" : "black",
              padding: "0.5rem 1rem",
              borderRadius: "1rem",
              marginBottom: "0.5rem",
              maxWidth: "80%",
              display: "flex",
              alignItems: "flex-start",
              wordBreak: "break-word", // 添加这一行
              overflowWrap: "break-word", // 添加这一行
            }}
          >
            <span>{processText(chat.text)}</span>
          </div>
        ))}
        {isLoading && (
          <div
            style={{
              alignSelf: "flex-start",
              backgroundColor: "#e5e5ea",
              color: "black",
              padding: "0.5rem 1rem",
              borderRadius: "1rem",
              marginBottom: "0.5rem",
              maxWidth: "80%",
            }}
          >
            <div style={typingAnimationStyle}>
              <div style={{...dotStyle, left: '0px', animationDelay: '0s'}}></div>
              <div style={{...dotStyle, left: '20px', animationDelay: '0.5s'}}></div>
              <div style={{...dotStyle, left: '40px', animationDelay: '1s'}}></div>
            </div>
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit} style={{ display: "flex" }}>
        <input
          type="text"
          value={inputText}
          onChange={handleInputChange}
          placeholder="Type your message..."
          style={{
            flex: 1,
            padding: "0.5rem",
            marginRight: "0.5rem",
            borderRadius: "4px",
            border: "1px solid #e0e0e0",
          }}
        />
        <button
          type="submit"
          disabled={isLoading}
          style={{
            padding: "0.5rem 1rem",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "4px",
            fontSize: "0.9rem",
            fontWeight: "bold",
            transition: "background-color 0.3s",
            opacity: isLoading ? 0.5 : 1,
            cursor: isLoading ? "not-allowed" : "pointer",
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#0056b3";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "#007bff";
          }}
        >
          {isLoading ? "Sending..." : "Send"}
        </button>
      </form>
    </div>
  );
}

export default ChatbotWindow;
