function generateRecommendation(productInfo, formData) {
    const { height, weight, shoulderWidth, bust, waist, hip } = formData;
    const { tag } = productInfo;
  
    let recommendation = "";
  
    if (height && weight) {
      const bmi = weight / (height / 100) ** 2;
      if (bmi < 18.5) {
        recommendation +=
          "Based on your height and weight, you have a slim build. We recommend choosing smaller sizes for a better fit.\n";
      } else if (bmi >= 18.5 && bmi < 25) {
        recommendation +=
          "Based on your height and weight, you have an average build. We recommend choosing regular sizes for a comfortable fit.\n";
      } else {
        recommendation +=
          "Based on your height and weight, you have a larger build. We recommend choosing larger sizes or considering plus-size options for a better fit.\n";
      }
    }
  
    if (shoulderWidth && tag.includes("w")) {
      recommendation += `With a shoulder width of ${shoulderWidth}cm, we recommend paying attention to the shoulder fit when selecting tops and dresses.\n`;
    }
  
    if (bust && waist && hip && tag.includes("w")) {
      recommendation += `With your bust, waist, and hip measurements of ${bust}cm, ${waist}cm, and ${hip}cm respectively, we recommend choosing styles that flatter your body shape, such as... (add specific style recommendations based on body shape).\n`;
    }
  
    if (!recommendation) {
      recommendation =
        "Please provide more information for personalized recommendations.";
    }
  
    return recommendation;
  }
  
  export function getRecommendation(productInfo, formData) {
    const recommendation = generateRecommendation(productInfo, formData);
    return { recommendation };
  }