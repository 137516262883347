import React, { useEffect, useState, useRef } from "react";
import { useGesture, useDrag } from "@use-gesture/react";
import { a, useSpring, animated } from "@react-spring/web";
import AppConfig from "./Config";
import { Center } from "@react-three/drei";
import Dressup from "./Dressup";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isAndroid,
} from "react-device-detect";

import "../components/style.css";
import AdviceForm from "../components/AdviceForm";
import RotatingModel from "../components/RotatingModel";

const hostloca = AppConfig.cdnUrl;

var jsondata = null;
var totaltum = 0;

let picktag = "w";

// 确保图片加载完成

function Dressitem(props) {
  const imageRef = useRef();
  const [imgalpha, setimgalpha] = useState(1);
  const [bgcolor, setbgcolor] = useState("#fff");
  let firstin = 0;

  const [tagimg, settagimg] = useState("blank.png");

  let winh = window.innerHeight;

  useEffect(() => {
    imageRef.current.onload = async () => {
      props.loaded(true);
      //  setimgalpha(1)
    };

    props.tag.forEach((element) => {
      if (element == "k") {
        settagimg("kittag.png");
      }
    });

    if (props.itemindex == props.targetitem) {
      //setbgcolor('#eeeeee')
    } else {
      // setbgcolor('#fff')
    }
  }, [props]);

  return (
    <div
      style={{
        width: "45vw",
        height: "55vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "1vw",
        backgroundImage: "url(" + hostloca + "Assets/shadow.png)",
        backgroundSize: "40vw 40vw",
        backgroundPosition: "2.5vw 10vw",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        style={{
          width: "45vw",
          height: "55vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: "url(" + hostloca + "Assets/" + tagimg + ")",
          backgroundSize: "28vw 28vw",
          backgroundPosition: "15vw 0vw",
          backgroundRepeat: "no-repeat",
        }}
      >
        <img
          ref={imageRef}
          src={props.src}
          style={{
            width: "98%",
            height: "80%",
            marginTop: "-30%",
            opacity: imgalpha,
            transition: `all .4s`,
          }}
        />
      </div>
    </div>
  );
}

function Tryon(props) {
  const ww = window.width;
  const wh = window.height;
  const [tryonstyle, settryonstyle] = useSpring(() => ({
    border: "0px solid #000",
    width: "50vw",
    height: "10vw",
    opacity: 1,
    visibility: "visible",
    scale: 1,
    borderRadius: "0vw",
  }));

  useEffect(() => {
    if (props.state == 1) {
      settryonstyle.start({
        opacity: 1,
        scale: 1.1,
        config: { duration: 200 },
      });

      setTimeout(() => {
        settryonstyle.start({
          opacity: 1,
          scale: 1,
          config: { duration: 200 },
        });
      }, 300);
    } else {
    }
  }, [props]);

  return (
    <a.div className={"alignMid"} style={tryonstyle}>
      <div
        className={"trybtn"}
        style={{
          border: "0px solid #000",
          width: "90%",
          height: "90%",
          backgroundColor: "black",
          borderRadius: "0vw",
        }}
      >
        TRY ON
      </div>
    </a.div>
  );
}

{
  /* 2024-06-07 增加Advice按钮 */
}
function Advice(props) {
  const ww = window.width;
  const wh = window.height;
  const [tryonstyle, settryonstyle] = useSpring(() => ({
    border: "0px solid #000",
    width: "50vw",
    height: "10vw",
    opacity: 1,
    visibility: "visible",
    scale: 1,
    borderRadius: "0vw",
  }));

  useEffect(() => {
    if (props.state == 1) {
      settryonstyle.start({
        opacity: 1,
        scale: 1.1,
        config: { duration: 200 },
      });

      setTimeout(() => {
        settryonstyle.start({
          opacity: 1,
          scale: 1,
          config: { duration: 200 },
        });
      }, 300);
    } else {
    }
  }, [props]);

  return (
    <a.div className={"alignMid"} style={tryonstyle}>
      <div
        className={"trybtn"}
        style={{
          border: "0px solid #000",
          width: "90%",
          height: "90%",
          backgroundColor: "black",
          borderRadius: "0vw",
        }}
      >
        ADVICE
      </div>
    </a.div>
  );
}

function Dressinfo(props) {
  const [itemsname, setitemsname] = useState("");
  const [itemsprice, setitemsprice] = useState("");
  const [infoalpha, setinfoalpha] = useState(0);

  useEffect(() => {
    setitemsname(jsondata[props.dressid].name);
    setitemsprice(jsondata[props.dressid].price);
    setinfoalpha(1);
  }, [props]);

  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        textAlign: "center",
        top: window.innerHeight - window.innerHeight * 0.33,
        left: 0,
        opacity: infoalpha,
        transition: `all .7s`,
      }}
    >
      <div
        className={"text1"}
        style={{
          color: "#111",
          fontWeight: "500",
          fontSize: "12px",
          width: "90vw",
          lineHeight: "24px",
          paddingLeft: "5vw",
          fontWeight: "1000",
        }}
      >
        {itemsname}
      </div>

      <div
        className={"text1"}
        style={{
          color: "#111",
          fontSize: "14px",
          paddingTop: "1vw",
          fontWeight: "800",
        }}
      >
        {itemsprice}
      </div>
    </div>
  );
}

function Dressvideo(props) {
  const [vurl, setvurl] = useState("");
  const [videoalpha, setvideoalpha] = useState(0);
  const [usevideo, setusevideo] = useState(false);

  useEffect(() => {
    var url = jsondata[props.dressid].video;

    if (url == "") {
      var tumburl = jsondata[props.dressid].tumb;
      setvurl(hostloca + tumburl);
      console.log(hostloca + tumburl);
      setusevideo(false);
    } else {
      setvurl(hostloca + url);
      setusevideo(true);
    }

    setvideoalpha(1);
  }, [props]);

  return (
    <div
      style={{
        position: "fixed",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        left: 0,
        backgroundColor: "white",
      }}
    >
      {usevideo ? (
        <video
          src={vurl} // 替换为你的视频文件路径
          autoPlay // 视频自动播放
          loop // 视频循环播放
          muted // 视频静音，部分浏览器要求视频自动播放时必须静音
          playsInline // 在 iOS 设备上全屏播放
          style={{
            width: "100%",
            height: "auto",
            marginTop: "-30vh",
            opacity: videoalpha,
            transition: `all .5s`,
          }} // 根据需要调整样式
          controls={false} // 不显示浏览器默认的视频控制组件
        ></video>
      ) : (
        // <img
        //   src={vurl}
        //   style={{
        //     width: "70%",
        //     height: "auto",
        //     marginTop: "-30vh",
        //     opacity: videoalpha,
        //     transition: `all .5s`,
        //   }}
        //   alt={jsondata[props.dressid].name}
        // />
        <div style={{ marginTop: "-50vh" }}>
            <RotatingModel selectedDress={jsondata[props.dressid]} />
          </div>
      )}

      <Dressinfo dressid={props.dressid} />
    </div>
  );
}

/**
 *
 * @param {*} props
 * @returns
 */

function ProgressBar(props) {
  // 确保进度值在 0 到 100 之间
  const validProgress = Math.min(Math.max(props.progress, 0), 100);

  return (
    <div className="progress-bar">
      <div className="progress" style={{ width: `${validProgress}%` }}></div>
    </div>
  );
}

function Select(props) {
  const [iniitems, setiniitems] = useState(true);
  const [dressitems, setdressitems] = useState([]);
  const [showvideo, setshowvideo] = useState(false);
  const [pickdressid, setpickdressid] = useState(0);
  const [guidetop, setguidetop] = useState(window.innerHeight);
  const [guidealpha, setguidealpha] = useState(0);
  const [guideanima, setguideanima] = useState(0);
  const [showpick, setshowpick] = useState("none");
  const [uploadblock, setuploadblock] = useState("none");
  const [tryonui, settryonui] = useState(true);
  const [tryonalpha, settryonalpha] = useState(0);
  const [tumblistalpha, settumblistalpha] = useState(0);
  const [showpageall, setshowpageall] = useState(0);

  // 2024-06-17 advice
  const [showAdvice, setShowAdvice] = useState(false);

  const [pickboardalpha, setpickboardalpha] = useState(0);

  const [fileinput, setfileinput] = useState(true);

  const [caminput, setcaminput] = useState(false);
  const [tumbloadprogress, settumbloadprogress] = useState(0);
  const [showprogress, setshowprogress] = useState(false);

  const [dresspid, setdresspid] = useState(0);

  const [bgblock, setbgblock] = useState("block");

  const fileInputRef = useRef(null);

  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    // 使用 URLSearchParams API 解析查询字符串
    let searchParams = new URLSearchParams(location.search);
    let pid = searchParams.get("id"); //URL pid转化成id 用来查询 index
    let step = searchParams.get("st");

    let toPCurl = hostloca + "dt?id=" + pid + "&st=" + step;
    if (isMobile) {
      if (window.innerHeight > window.innerWidth) {
        setshowpageall(1);
      } else {
        window.location.href = toPCurl;
      }
    } else {
      window.location.href = toPCurl;
    }
  }, []);

  useEffect(() => {
    const url = hostloca + "/data/dress.json";

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        const { results } = json;

        jsondata = json.qadata;

        setdressitems(jsondata);

        // 使用 URLSearchParams API 解析查询字符串
        let searchParams = new URLSearchParams(location.search);
        let pid = searchParams.get("id"); //URL pid转化成id 用来查询 index
        setdresspid(pid);
        let step = searchParams.get("st");

        let id = 0;

        console.log("url params id=:" + id + "pid: " + pid + "step :" + step);

        //home page
        if (step == null || step == 1 || step == undefined || step == "") {
          setTimeout(() => {
            setiniitems(true);
            settumblistalpha(1);
            settryonui(true);
            settryonalpha(1);
          }, 1000);
        } else {
          // 使用.findIndex()查找匹配的项的索引
          const index = jsondata.findIndex((item) => item.pid === pid);

          // 检查是否找到匹配项
          if (index !== -1) {
            id = index;
            // 在这里执行你的操作，例如更新状态、调用函数等

            picktag = jsondata[id].tag;

            console.log("rechoose tag :" + picktag);

            if (step == 2) {
              setpickdressid(id);
              settryonui(true);
              settryonalpha(1);
              setshowvideo(true);
              setshowprogress(false);
            }

            if (step == 3) {
              setpickdressid(id);
              setpickboardalpha(1);
              // setshowvideo(true)
              setguidetop(0);
              setguidealpha(1);
              setshowpick("block");
              setshowprogress(false);

              // setTimeout(() => {

              // }, 500);
            }
          } else {
            console.log("No item found with pid: ", pid);
          }
        }

        // console.log(jsondata)
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [props]);

  function itemclick(e, topid) {
    setpickdressid(e);
    setshowvideo(true);
    settumblistalpha(0);
    setdresspid(topid);

    console.log(
      "pick dressid: " + e + " pid :" + topid + " tag:" + jsondata[e].tag
    );

    picktag = jsondata[e].tag;
  }

  function backtolist() {
    setiniitems(true);
    setshowvideo(false);
    setpickboardalpha(1);
    settumblistalpha(1);
  }

  function Tryonbtn() {
    setguideanima(0.3);
    setguidetop(0);

    setpickboardalpha(1);
  }

  function Advicebtn() {
    setShowAdvice(true);
  }

  function canceltry() {
    setguidetop(window.innerHeight);
    setguidealpha(0);
    settryonui(true);
    setshowvideo(true);

    settryonalpha(1);
  }

  function pick() {
    setcaminput(false);
    setfileinput(true);
    setguidealpha(1);
    setshowpick("block");
  }

  function pickcam() {
    setcaminput(true);
    setfileinput(false);
    setguidealpha(1);
    setshowpick("block");
  }

  function cancelpick() {
    setguidealpha(0);
    setshowpick("none");
    settumblistalpha(1);
  }

  function submitphoto() {
    navigate("/dressing");
  }

  function itemtumbload(e) {
    if (e == true) {
      totaltum += 1;
      setshowprogress(true);
      settumbloadprogress((totaltum / jsondata.length) * 100);
      settumblistalpha(0);
      settryonalpha(0);
      if (totaltum >= jsondata.length - 5) {
        console.log("tumb load" + totaltum);
        settumblistalpha(1);
        settryonui(true);
        settryonalpha(1);
        setshowprogress(false);
        setbgblock("none");
      }
    }
  }

  /**
   *
   */

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(
        "/appinter/posesent.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      if (response.ok) {
        console.log("File uploaded successfully: " + hostloca + result.url);
        setiniitems(false);
        var photopath = result.url;
        navigate("/dressing", {
          state: {
            dressid: pickdressid,
            photourl: photopath,
            tag: picktag,
            userdevice: "mobile",
          },
        });
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.log("Upload failed: " + error.message);
    }
  };

  const compressAndUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // 设置压缩后图片的最大宽度和高度
        const maxWidth = 1000;
        const scale = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scale;

        // 绘制调整大小的图片
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // 将图片导出为 blob 并上传
        canvas.toBlob(
          (blob) => {
            uploadFile(blob);
            setuploadblock("flex");
            console.log("try upload blob");
          },
          "image/jpeg",
          1
        ); // 调整导出的图片格式和质量
      };
    };
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // 判断文件大小，大于 2MB 进行压缩
      if (file.size > 2 * 1024 * 1024) {
        compressAndUpload(file);
        console.log("compress photo");
      } else {
        // 文件大小小于 2MB，直接上传
        uploadFile(file);
        setuploadblock("flex");
        console.log("try upload file");
      }
    }
  };

  const handleClick = () => {
    fileInputRef.current.click(); // 触发隐藏的文件输入点击事件
    //test mode
    // navigate('/dressing',{ state: { dressid: pickdressid, photourl:'',tag:picktag,userdevice:'mobile'}});
  };

  let dressitemgroup_top =
    (window.innerHeight - window.innerWidth * 0.55 * 2) / 2;

  let coverlayer_top = dressitemgroup_top + window.innerWidth * 0.55 * 2;

  return (
    <div style={{ opacity: showpageall, backgroundColor: "#fff" }}>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "white",
          overflowY: "scroll",
          display: "flex",
          scrollBehavior: "smooth",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "90vw",
            float: "left",
            textAlign: "left",
            flexDirection: "row",
            display: "flex",
            flexWrap: "wrap",
            paddingTop: dressitemgroup_top,
            opacity: tumblistalpha,
            transition: `all .6s`,
          }}
        >
          {iniitems
            ? dressitems.map((dressitem, index) => (
                <div
                  key={index}
                  onClick={() => itemclick(index, dressitem.pid)}
                  style={{}}
                >
                  <Dressitem
                    src={dressitem.tumb}
                    loaded={itemtumbload}
                    targetitem={pickdressid}
                    tag={dressitem.tag}
                    itemindex={index}
                  />
                </div>
              ))
            : ""}
          <div style={{ width: "45vw", height: "50vw", display: "flex" }} />
          <div style={{ width: "45vw", height: "50vw", display: "flex" }} />
        </div>
      </div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          backgroundColor: "white",
          left: 0,
          top: coverlayer_top,
        }}
      >
        {" "}
      </div>
      {showvideo ? <Dressvideo dressid={pickdressid} /> : ""}
      {/* 2024-06-17 Advice UI */}
      {showAdvice && <AdviceForm selectedDress={jsondata[pickdressid]} onClose={() => setShowAdvice(false)} />}
      {tryonui ? (
        <div style={{ opacity: tryonalpha, transition: `all .5s` }}>
          <div
            onClick={() => Tryonbtn()}
            style={{
              position: "absolute",
              alignSelf: "center",
              top: coverlayer_top + window.innerWidth * 0.08,
              left: (window.innerWidth * 0.5) / 2,
            }}
          >
            <Tryon state={pickboardalpha} />
          </div>

          <div
            onClick={() => Advicebtn(true)}
            style={{
              position: "absolute",
              alignSelf: "center",
              top: coverlayer_top + window.innerWidth * 0.18,
              left: (window.innerWidth * 0.5) / 2,
            }}
          >
            <Advice state={pickboardalpha} />
          </div>

          <img
            onClick={() => backtolist()}
            src={hostloca + "Assets/backbtn.png"}
            style={{
              width: "13vw",
              position: "fixed",
              top: "4vh",
              left: "82vw",
            }}
          />

          <img
            src={hostloca + "Assets/logo.png"}
            style={{
              width: "40vw",
              position: "fixed",
              top: "2.5vh",
              left: "30vw",
            }}
          />

          <img
            onClick={() => backtolist()}
            src={hostloca + "Assets/listbtn.png"}
            style={{
              width: "14vw",
              position: "fixed",
              top: "4vh",
              left: "5vw",
            }}
          />
        </div>
      ) : (
        ""
      )}
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: guidetop,
          left: 0,
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: `all ` + guideanima + `s`,
          opacity: 1,
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            opacity: pickboardalpha,
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100vw",
              height: "100vh",
              left: 0,
              top: 0,
              backgroundColor: "#eae1da",
              opacity: 1,
            }}
          />
          <img
            src={hostloca + "Assets/pick.png"}
            style={{
              position: "absolute",
              width: "100%",
              marginTop: 0,
              top: (window.innerHeight - (window.innerWidth * 1904) / 1125) / 2,
            }}
          />
          <div
            onClick={() => canceltry()}
            style={{
              position: "absolute",
              width: "30vw",
              height: "30vw",
              top: (window.innerHeight - (window.innerWidth * 1904) / 1125) / 2,
              left: window.innerWidth * 0.8,
            }}
          ></div>
          <div
            onClick={() => pickcam()}
            style={{
              position: "absolute",
              width: "50vw",
              height: "50vw",
              top: (window.innerHeight - window.innerWidth * 0.5) / 2,
              left: 0,
            }}
          ></div>
          <div
            onClick={() => pick()}
            style={{
              position: "absolute",
              width: "50vw",
              height: "50vw",
              top: (window.innerHeight - window.innerWidth * 0.5) / 2,
              right: 0,
            }}
          ></div>
        </div>

        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            left: 0,
            top: 0,
            display: showpick,
            opacity: guidealpha,
            justifyContent: "center",
            transition: `all .3s`,
            backgroundColor: "white",
          }}
        >
          <img
            src={hostloca + "Assets/poseguide.jpg"}
            style={{
              position: "absolute",
              width: "100%",
              top:
                (window.innerHeight - (window.innerWidth * 1904) / 1125) / 2 -
                window.innerWidth * 0.02,
            }}
          />

          {fileinput ? (
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              style={{ display: "none" }} // 隐藏文件输入
            />
          ) : (
            ""
          )}

          {caminput ? (
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              capture="camera"
              style={{ display: "none" }} // 隐藏文件输入
            />
          ) : (
            ""
          )}

          <div
            onClick={() => handleClick()}
            className={"shopbtn"}
            style={{
              position: "absolute",
              top:
                (window.innerHeight - (window.innerWidth * 1904) / 1125) / 2 +
                (window.innerWidth * 1904) / 1125 -
                window.innerWidth * 0.14,
              left: "32vw",
              alignSelf: "center",
              justifySelf: "center",
            }}
          >
            CONTINUE
          </div>

          {/*
                            <div onClick={()=>submitphoto()}    className={'shopbtn'} style={{position:'absolute',top:'80vh',left:'32vw',alignSelf:'center',justifySelf:'center'}}>CONTINUE</div>
                          */}
          <div
            onClick={() => cancelpick()}
            style={{
              position: "absolute",
              width: "30vw",
              height: "30vw",
              top:
                (window.innerHeight - (window.innerWidth * 1904) / 1125) / 2 -
                window.innerWidth * 0.02,
              left: window.innerWidth * 0.8,
            }}
          ></div>
        </div>

        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#222",
            opacity: 0.9,
            display: uploadblock,
            textAlign: "center",
            color: "white",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={hostloca + "Assets/loading3.gif"}
            style={{ width: "20vw", marginTop: "-16vh" }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          left: 0,
          top: 0,
          backgroundColor: "#fff",
          display: bgblock,
        }}
      ></div>
      {showprogress ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 1,
          }}
        >
          <div style={{ width: "50%", marginTop: "-16vh" }}>
            <ProgressBar progress={tumbloadprogress} />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Select;