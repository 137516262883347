// config.js

const AppConfig = {
 // cdnUrl:'https://cdn.irabit.com/lp/',
  cdnUrl:'./',
  maxItemsPerPage: 10,
  testmode:'none',
  // 添加其他全局配置项
};

export default AppConfig;