import React, { useEffect, useState } from 'react';
import { useGesture , useDrag} from '@use-gesture/react';
import { a,useSpring, animated } from '@react-spring/web'
import  './style.css';
import AppConfig from '../Home/Config';
import { Center } from '@react-three/drei';

const hostloca=AppConfig.cdnUrl;
var jsondata=''

function Dressinfo(props) {

    const [itemsname,setitemsname]=useState('')
    const [itemsprice,setitemsprice]=useState('123')
    const [itemslink,setitemslink]=useState('')
    const [infoalpha,setinfoalpha]=useState(0)

    const [sizemarkalpha,setsizemarkalpha]=useState(0)
    const [barsize,setbarsize]=useState('22vw')
    const [barborderradio,setbarborderradio]=useState('2.2vh')
    const [barborderradiolt,sebarborderradiolt]=useState('0')
    const [showuksize,setshowuksize]=useState('block')
    const [sizearray,setsizearray] = useState(['UK 8','UK 10','UK 12','UK 14','UK 16'])
    const [targetsize,settargetsize] = useState(props.sizeid)

    

    useEffect(() => {

        jsondata=props.data
        if(jsondata.length>0 && props.show){

           
            setinfoalpha(1)

            setitemsprice(jsondata[props.dressid].price)
            setitemslink(jsondata[props.dressid].link)
            setitemsname(jsondata[props.dressid].name)

        }

        settargetsize(props.sizeid)

       

    },[props])


    function callcapture(){

        props.capturesphoto()
    }


    function sizebtnclick(){
        setbarsize('95vw')
        setbarborderradio('1vh')
        sebarborderradiolt('1vh')
        setsizemarkalpha(1)
        setshowuksize('none')
    }


    function sizeitemclick(e){
        
        setbarborderradio('2.2vh')
        
            sebarborderradiolt('0vh')
       
       
        setbarsize('22vw')
        setshowuksize('none')
        setsizemarkalpha(0)
        setshowuksize('block')

        settargetsize(e)


        props.clothsize(e)
        

    }


    function shopnow(){

        console.log('shop')
        let urlshop=jsondata[props.dressid].link
        console.log(urlshop)
       
       window.location.href=jsondata[props.dressid].link
    }




    return(
        <div style={{position:'absolute',width:'100vw',height:'100vh',textAlign:'center',top:window.innerHeight*.82,left:0,opacity:infoalpha,transition:`all .7s`}}>

           

          <div className={'text1'} style={{color:'#111',fontWeight:'500',fontSize:'12px',width:'90vw',lineHeight:'24px',paddingLeft:'5vw',fontWeight:'800'}}>{itemsname}</div>
         
          <div className={'text1'} style={{color:'#111',fontSize:'12px',paddingTop:'1vw',fontWeight:'800'}}>{itemsprice}</div>

        <div style={{width:'100vw',textAlign:'center',justifyContent:'right',paddingTop:'1vw',display:'flex',flexDirection:'row',alignItems:'center'}}>
         <div onClick={()=>shopnow()} className={'shopbtn'} style={{paddingTop:'1vw',marginRight:'8.5vw',backgroundColor:'black'}}>SHOP NOW</div>
         
          <div onClick={()=>callcapture()} style={{width:'10vw',height:'10vw',marginRight:'11vw'}}>


          <img  src={hostloca+'Assets/downloadbtn.png'} style={{width:'100%',height:'100%'}} />
          </div>

         
          </div>

          <div   style={{display:'none',width:barsize,height:'4.4vh',backgroundColor:'white',opacity:.8,position:'absolute',top:'-9vh',left:'2vw',borderBottomLeftRadius:barborderradio,borderBottomRightRadius:barborderradio,borderTopRightRadius:barborderradio,borderTopLeftRadius:barborderradiolt,overflow:'hidden',transition:`all .2s`}}> 
          
            <div style={{marginTop:'1.2vh',display:'flex',alignItems:'center',flexDirection:'row',alignContent:'center',opacity:sizemarkalpha,height:'2vh',overflow:'hidden'}}>
                <div  onClick={()=>sizeitemclick(0)} className={'text1'}  style={{width:'16vw',height:'2vh',backgroundColor:'white',borderRadius:'2vh',marginLeft:'3vw',fontSize:'12px',transition:`all 0.8s`,opacity:sizemarkalpha}}>UK 8 </div>
                <div  onClick={()=>sizeitemclick(1)} className={'text1'}  style={{width:'16vw',height:'2vh',backgroundColor:'white',borderRadius:'2vh',marginLeft:'2vw',fontSize:'12px',transition:`all 0.8s`,opacity:sizemarkalpha}}>UK 10 </div>
                <div  onClick={()=>sizeitemclick(2)} className={'text1'}  style={{width:'16vw',height:'2vh',backgroundColor:'white',borderRadius:'2vh',marginLeft:'2vw',fontSize:'12px',transition:`all 0.8s`,opacity:sizemarkalpha}}>UK 12 </div>
                <div  onClick={()=>sizeitemclick(3)} className={'text1'}  style={{width:'16vw',height:'2vh',backgroundColor:'white',borderRadius:'2vh',marginLeft:'2vw',fontSize:'12px',transition:`all 0.8s`,opacity:sizemarkalpha}}>UK 14</div>
                 <div onClick={()=>sizeitemclick(4)}  className={'text1'}  style={{width:'16vw',height:'2vh',backgroundColor:'white',borderRadius:'2vh',marginLeft:'2vw',fontSize:'12px',transition:`all 0.8s`,opacity:sizemarkalpha}}>UK 16 </div>
            </div>

            <div className={'text1'} onClick={()=>sizebtnclick()} style={{marginTop:'1.2vh',position:'absolute',width:'16vw',height:'3.4vh',backgroundColor:'white',borderRadius:'2vh',marginLeft:'3vw',fontSize:'12px',left:0,top:0,transition:`all 0s`,opacity:1,display:showuksize}}>{sizearray[targetsize]} </div>

           </div>

         
        </div>


    )


}

export default Dressinfo;