import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGesture , useDrag} from '@use-gesture/react';
import { a,useSpring, animated } from '@react-spring/web'
import AppConfig from './Config';
import { Center } from '@react-three/drei';
import Dressup from './Dressup';
import DressupPC from './DressupPC';

 
const hostloca=AppConfig.cdnUrl;


function Dressing(props) {
      
    const [intdress,setintdress]=useState(false)

    const [intdressPC,setintdressPC]=useState(false)

    const [dressidp,setdressidp]=useState(2)
    const [photourlp,setphotourlp]=useState('')
    const [dresstag,setdresstag]=useState('w')
    let location = useLocation();
    let { dressid, photourl ,tag,userdevice} = location.state || {}; // 注意：直接访问 location.state 之前最好进行存在性检查
  
     
    useEffect(() => {

        /*
        let searchParams = new URLSearchParams(location.search);
        let id = searchParams.get("id");  
         
        if(id == null || id==undefined){

        }else{
            setdressidp(id)
            setintdress(true)
        }


*/


      

   if(location.state){
    if(dressid==null || dressid==undefined ){
      console.log('undefined dressid use def ')
      
       if(userdevice=='mobile'){
        setintdress(true)
       }else if(userdevice=='pc'){
        setintdressPC(true)
       }
     
    }  else{
        console.log('get dressid id :'+dressid + ' url :'+photourl + ' tag :'+tag + ' device:'+userdevice) 
        setdressidp(dressid)
        setphotourlp(photourl)
        setdresstag(tag)
         
       if(userdevice=='mobile'){
        setintdress(true)
       }else if(userdevice=='pc'){
        setintdressPC(true)
       }

    }  

   }
   

       

    },[props,location])

    return(
                <div>
                     {intdress? (
               <Dressup dressid={dressidp} photourl={photourlp} tag={dresstag}/>
               ):('')}

               {intdressPC? (
               <DressupPC dressid={dressidp} photourl={photourlp} tag={dresstag}/>
               ):('')}

                </div>

          )

}


export default Dressing