import { useState, useEffect, useRef } from "react";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Html, OrbitControls, PerspectiveCamera } from '@react-three/drei';
import * as THREE from 'three';


function Model() {
  const [glb, setGlb] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const modelRef = useRef();

  useEffect(() => {
    new GLTFLoader().load(
      "dressModel/1.glb",
      (loadedGlb) => {
        setGlb(loadedGlb);
        setIsLoading(false);

        // 计算模型的边界框
        const box = new THREE.Box3().setFromObject(loadedGlb.scene);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());

        // 计算适当的缩放比例
        const maxDim = Math.max(size.x, size.y, size.z);
        const scale = 1 / maxDim; // 假设我们希望模型最大尺寸为8个单位

        // 更新模型的位置和比例
        loadedGlb.scene.scale.setScalar(scale);
        loadedGlb.scene.position.sub(center.multiplyScalar(scale));
        loadedGlb.scene.position.y += -size.y * scale * 0.25; // 向下移动1/4高度;
      },
      undefined,
      (err) => {
        setError(err);
        setIsLoading(false);
      }
    );
  }, []);

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.01;
    }
  });

  if (isLoading) return <Html><p>Loading model...</p></Html>;
  if (error) return <Html><div>Error loading model: {error.message}</div></Html>;

  return (
    <group ref={modelRef}>
      <primitive object={glb.scene} />
    </group>
  );
}

function CameraController() {
  const { camera, scene } = useThree();
  const cameraRef = useRef();
  
  useFrame(() => {
    if (cameraRef.current && !cameraRef.current.hasMoved) {
      // 计算场景的边界框
      const box = new THREE.Box3().setFromObject(scene);
      const center = box.getCenter(new THREE.Vector3());
      const size = box.getSize(new THREE.Vector3());

      // 设置相机位置和目标
      const maxDim = Math.max(size.x, size.y, size.z);
      camera.position.set(center.x, center.y, center.z + maxDim * 2);
      camera.lookAt(center);
      camera.fov = 40;
      camera.updateProjectionMatrix();

      cameraRef.current.hasMoved = true;
    }
  });

  return null;
}

function RotatingModel({ selectedDress }) {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Canvas>
        <PerspectiveCamera fov={40} />
        <ambientLight intensity={2} />
        <directionalLight position={[5, 5, 5]} intensity={2} />
        <Model />
        <OrbitControls 
          minDistance={5} 
          maxDistance={30}
          minPolarAngle={Math.PI/6}
          maxPolarAngle={Math.PI/2}
          target={[0, 0, 0]}
        />
        <CameraController />
      </Canvas>
    </div>
  );
}

export default RotatingModel;